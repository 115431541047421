import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Import translation files
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Use 'en' if current language translation is not available
    interpolation: {
      escapeValue: false // React already safe from XSS
    }
  });

export default i18n;