import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CommonQuestions: React.FunctionComponent<{}> = (props) => {

    const [isShowQues1, setIsShowQues1] = useState<Boolean>(true);
    const [isShowQues2, setIsShowQues2] = useState<Boolean>(true);
    const [isShowQues3, setIsShowQues3] = useState<Boolean>(true);
    const [isShowQues4, setIsShowQues4] = useState<Boolean>(true);
    const [isShowQues5, setIsShowQues5] = useState<Boolean>(true);
    const [isShowQues6, setIsShowQues6] = useState<Boolean>(true);
    const { t, i18n } = useTranslation();


    return (
        <div className="service-sec service-common dir-rtl">
            <div className="container">
                <div className="row ">
                    <h2>{t("Frequently Asked Questions")}</h2>
                </div>
                <div className="row">

                    <div className=" col-md-12">
                        <div className="card-common">
                            <div className="question-gras">
                                {t("How does your pricing")}
                                {isShowQues1 ? <span className="pointer-right" onClick={() => setIsShowQues1(!isShowQues1)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues1(!isShowQues1)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>
                            {isShowQues1 == false && <><p className="question " >
                                {t("Our pricing is structured across four subscription")}</p>
                                <p className="question spaced-text" >  {t("Our pricing is based on a credit")}</p>
                                <p className="question spaced-text" >  {t("Once credits are deducted for the initial")}
                                </p></>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("What is a photo upload")}
                                {isShowQues2 ? <span className="pointer-right" onClick={() => setIsShowQues2(!isShowQues2)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues2(!isShowQues2)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>
                            {isShowQues2 == false && <>
                            <p className="question" > ● <span style={{fontWeight:"700", margin:"0px 5px"}}>{t("Photo Upload")} :</span> {t("Upload an image of your room")}  </p>
                            <p className="question spaced-text" >  ● <span style={{fontWeight:"700", margin:"0px 5px"}}>{t("Render")} :</span> {t("The output generated by our AI")}  </p>
                          
                            </>}
                        </div>

                        <div className="card-common">
                            <div className="question-gras">
                                {t("Can I remove the watermark")}
                                {isShowQues3 ? <span className="pointer-right" onClick={() => setIsShowQues3(!isShowQues3)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues3(!isShowQues3)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>
                            {isShowQues3 == false && <p className="question" >
                                {t("No, the Free Plan includes")}
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("How long does it take to virtually")}
                                {isShowQues4 ? <span className="pointer-right" onClick={() => setIsShowQues4(!isShowQues4)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues4(!isShowQues4)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>
                            {isShowQues4 == false && <p className="question" >
                                {t("Our AI works in real time and typically")} <span style={{fontWeight:"700", margin:"0px 5px"}}>{t("seconds to a few minutes")}</span> {t("depending on the complexity")}
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("Can I upload my own style")}
                                {isShowQues5 ? <span className="pointer-right" onClick={() => setIsShowQues5(!isShowQues5)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues5(!isShowQues5)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>
                            {isShowQues5 == false && <p className="question" >
                                {t("Yes! With our")}  <span style={{fontWeight:"700", margin:"0px 5px"}}>{t("Style Transfer")}</span> {t("feature, you can upload an image")}
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                {t("How does the 2D to 3D floor")}
                                {isShowQues6 ? <span className="pointer-right" onClick={() => setIsShowQues6(!isShowQues6)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                    : <span className="pointer-right" onClick={() => setIsShowQues6(!isShowQues6)}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.00005 2.12505L14 14.125M2 14.1251L14 2.1251" stroke="#1C274C" strokeWidth="2.5" strokeLinecap="round" />
                                        </svg>

                                    </span>}
                            </div>

                            {isShowQues6 == false && <p className="question" >
                                {t("Upload your 2D floor plan to the platform")}
                            </p>}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default CommonQuestions;