import { useTranslation } from "react-i18next";
import ButtonProfile from "./button-profile";

const Header: React.FunctionComponent<{ title: any, User: any }> = (props) => {


  const { t } = useTranslation();

  const getSidebar = (event: any) => {
    event.preventDefault();
    $(".sidebar").toggleClass("active");
  }


  return (

    <nav className="display-none marg-t-20 justify-content-between">
      <div className="d-flex align-items-center">
        <button className="navbar-toggler" type="button" onClick={getSidebar}>
          <span>
            <svg width="20" height="20" style={{ marginTop: "-4px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.14844 6.1875H16.8627" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M3.14844 10H16.8627" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M3.14844 13.8086H16.8627" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </span>
        </button> 
          <h1 className='title'>{t(`${props.title}`)}</h1>
      </div>
      <div className="navbar-menu-wrapper d-flex ">
        <ButtonProfile/>
      </div>
    </nav>

  )
}
export default Header