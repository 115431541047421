import React, { useState } from "react";
import { ContactUs } from "../../Services/contact-us";
import { useTranslation } from "react-i18next";

const Contact: React.FunctionComponent<{}> = (props) => {

	const [errorMessage, setErrorMessage] = useState<string>();
	const [successMessage, setSuccessMessage] = useState<string>();
	const [errors, setErrors] = useState<any>({ "name": "", "email": "", "message": "" });
	const [formData, setFormData] = useState<any>({ "name": "", "email": "", "message": "" });
    const { t, i18n } = useTranslation();


	const handleChangeName = (event: any) => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			name: event.target.value,
		}));
	}

	const handleChangeEmail = (event: any) => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			email: event.target.value,
		}));
	}

	const handleChangeMessage = (event: any) => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			message: event.target.value,
		}));
	}

	const handleSend = (event: any) => {
		setErrorMessage("")
		setSuccessMessage("")
		event.preventDefault();
		if (!formData.email) {
			setErrors((prevFormData: any) => ({
				...prevFormData,
				email: 'Please enter your email address',
			}));
		}
		if (!formData.name) {
			setErrors((prevFormData: any) => ({
				...prevFormData,
				name: 'Please enter your name',
			}));
		}
		if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
			setErrors((prevFormData: any) => ({
				...prevFormData,
				email: 'Please enter your email in the correct format',
			}));
		}
		// Validation message 
		if (!formData.message) {
			setErrors((prevFormData: any) => ({
				...prevFormData,
				message: 'Please enter your message',
			}));
		} else {
			ContactUs({ "name": formData.name, "email": formData.email, "message": formData.message }).then(res => {
				setSuccessMessage('Email send successfully')
			}).catch((error) => {
				setErrorMessage("Please try again. Your message hasn't been sent.")
			})
		}
		setTimeout(() => {
			setErrorMessage("")
			setSuccessMessage("")
		}, 3000)
	}

	return (
		<div className="padd-80 dir-rtl">
			<div className="container">
				<div className="row card-contact">
					<div className="col-md-7 order-11">
						<div className="contact-sec ">
							<h3 className="marg">{t("Contact Us")}</h3>
							{errorMessage && <div className="alert alert-danger" role="alert">{t(`${errorMessage}`)}</div>}
							{successMessage && <div className="alert alert-success" role="alert">{t(`${successMessage}`)}</div>}

							<form id="contactform" className="contact-form" onSubmit={handleSend}>
								<div className="form-group">
									<div className="col marg-form-group">
										<input type="text" className="form-control" placeholder={t("Name")}
											name="contact_name" id="contact_name" value={formData.name} onChange={handleChangeName} />
										{errors.name && <span className="error">{t(`${errors.name}`)}</span>}
									</div>
									<div className="col-email marg-form-group ">
										<input type="email" className="form-control" placeholder={t("Email")}
											name="contact_email" id="contact_email" value={formData.email} onChange={handleChangeEmail} />
										{errors.email && <span className="error">{t(`${errors.email}`)}</span>}
									</div>
								</div>
								<div className="form-group">
									<textarea className="form-control" placeholder={t("Message")} name="contact_message" value={formData.message} onChange={handleChangeMessage}
										id="contact_message"></textarea>
									{errors.message && <span className="error"> {t(`${errors.message}`)}</span>}
								</div>
								<button type="submit" className="btn">{t("SEND US")}</button>
							</form>
						</div>
					</div>
					<div className="col-md-5 order-00">
						<div className="contact-img">
							<img src="/assets/images/Contact.webp" alt="contact" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Contact;