import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DesignStyles: React.FunctionComponent<{ listStyle: any }> = (props) => {

    const [image, setImage] = useState<any>();
    const { t } = useTranslation();


    return (
        <>
            <div className="row mt-3 ">
                {props.listStyle && props.listStyle.map((img: any, index: any) => {
                    return <div className=' col-md-3 mb-3' key={index}>
                        <div className='image-hover card-style'>
                            <img src={img.src} alt={`Style ${index}`} className='img-style' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setImage(img.src) }} />
                            <div className="tag-img-style ">{t(`${img.style}`)}</div>
                        </div>
                    </div>
                })}
            </div>

            <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <img src={image} alt={`Style`} className='img-modal' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DesignStyles;