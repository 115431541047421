import React, { useState } from "react";
import Pricing from "./pricing";

const PricingPage: React.FunctionComponent<{}> = (props) => {

  

    return (
        <div className="padd-80">
            <Pricing />
        </div>
    )
}
export default PricingPage;