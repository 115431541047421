import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isLogged } from "./Authentication";

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuardEn: React.FC<AuthGuardProps> = ({ children }) => {


return isLogged() ? <Navigate to="/home" /> : <>{children}</>;}
  

export default AuthGuardEn;