import React, { useEffect, useRef, useState } from 'react';
import GenerateImages from './generate-images/generate-images';
import { GetAccount, isLogged } from '../../Services/Authentication';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Head from './head';
import Upgrade from './Upgrade';
import UpgradePrice from './upgrade-price';
import { useTranslation } from 'react-i18next';


const Dashboard: React.FunctionComponent<{}> = (props) => {

    const [Feature, setFeature] = useState<any>("Room Staging")
    const [change, setChange] = useState<any>(false);
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [account, setAccount] = useState<any>("");

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setAccount(resp)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, [change]);

    const handleClose = () => {
        setOpen(false);
    }

    const updateChangetValue = (newValue: any) => {
        setChange(newValue);
        console.log(newValue)
    };

    const UpdateFeatureHandler = (value: any) => {
        setFeature(value)
    }

    return  (
        <div className="page-dash ">
            <Head title={"Start Generating Designs"} User={account !== "" ? account : ""} />

            <div className='row m-50'>
                <div className='col-md-3 mb-3'>
                    <div className='card-body back-DCFFDE'>
                        <h3>{t("Room Staging")}</h3>
                        <button className='try back-29BD81' onClick={() => UpdateFeatureHandler("Room Staging")}>{t("Try Now")}</button>
                        <span className='credit-number'>20 {t("Credits")}</span>
                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <div className='card-body back-E5E2FF'>
                        {account?.subscription === "free" && <div className="overlay">
                            <div className="overlay-content">
                                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7214 20.5308V16.3359C10.7214 8.72476 16.8914 2.55469 24.5026 2.55469C32.1138 2.55469 38.2839 8.72476 38.2839 16.3359V20.5308C40.5598 20.7007 42.0419 21.1299 43.1253 22.2132C44.9193 24.0072 44.9193 26.8946 44.9193 32.6693C44.9193 38.444 44.9193 41.3313 43.1253 43.1253C41.3313 44.9193 38.444 44.9193 32.6693 44.9193H16.3359C10.5612 44.9193 7.67388 44.9193 5.87991 43.1253C4.08594 41.3313 4.08594 38.444 4.08594 32.6693C4.08594 26.8946 4.08594 24.0072 5.87991 22.2132C6.96328 21.1299 8.4454 20.7007 10.7214 20.5308ZM13.7839 16.3359C13.7839 10.4161 18.5828 5.61719 24.5026 5.61719C30.4224 5.61719 35.2214 10.4161 35.2214 16.3359V20.4266C34.4394 20.4193 33.5911 20.4193 32.6693 20.4193H16.3359C15.4141 20.4193 14.5658 20.4193 13.7839 20.4266V16.3359ZM16.3359 34.7109C17.4635 34.7109 18.3776 33.7969 18.3776 32.6693C18.3776 31.5417 17.4635 30.6276 16.3359 30.6276C15.2084 30.6276 14.2943 31.5417 14.2943 32.6693C14.2943 33.7969 15.2084 34.7109 16.3359 34.7109ZM24.5026 34.7109C25.6302 34.7109 26.5443 33.7969 26.5443 32.6693C26.5443 31.5417 25.6302 30.6276 24.5026 30.6276C23.375 30.6276 22.4609 31.5417 22.4609 32.6693C22.4609 33.7969 23.375 34.7109 24.5026 34.7109ZM34.7109 32.6693C34.7109 33.7969 33.7969 34.7109 32.6693 34.7109C31.5417 34.7109 30.6276 33.7969 30.6276 32.6693C30.6276 31.5417 31.5417 30.6276 32.6693 30.6276C33.7969 30.6276 34.7109 31.5417 34.7109 32.6693Z" fill="white" />
                                </svg>
                                <Upgrade class="upgrade-button" User={account} />
                            </div>
                        </div>}
                        <h3 className={`${account?.subscription === "free" ? "overlay-title" : ""}`}>{t("Garden Landscaping")}</h3>
                        <button className={`try back-573CFF ${account?.subscription === "free" ? "overlay-button" : ""}`} onClick={() => UpdateFeatureHandler("Garden Landscaping")}>{t("Try Now")}</button>
                        <span className='credit-number'>20 {t("Credits")}</span>

                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <div className='card-body back-F6EBE6' >
                        {account?.subscription === "free" && <div className="overlay">
                            <div className="overlay-content">
                                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7214 20.5308V16.3359C10.7214 8.72476 16.8914 2.55469 24.5026 2.55469C32.1138 2.55469 38.2839 8.72476 38.2839 16.3359V20.5308C40.5598 20.7007 42.0419 21.1299 43.1253 22.2132C44.9193 24.0072 44.9193 26.8946 44.9193 32.6693C44.9193 38.444 44.9193 41.3313 43.1253 43.1253C41.3313 44.9193 38.444 44.9193 32.6693 44.9193H16.3359C10.5612 44.9193 7.67388 44.9193 5.87991 43.1253C4.08594 41.3313 4.08594 38.444 4.08594 32.6693C4.08594 26.8946 4.08594 24.0072 5.87991 22.2132C6.96328 21.1299 8.4454 20.7007 10.7214 20.5308ZM13.7839 16.3359C13.7839 10.4161 18.5828 5.61719 24.5026 5.61719C30.4224 5.61719 35.2214 10.4161 35.2214 16.3359V20.4266C34.4394 20.4193 33.5911 20.4193 32.6693 20.4193H16.3359C15.4141 20.4193 14.5658 20.4193 13.7839 20.4266V16.3359ZM16.3359 34.7109C17.4635 34.7109 18.3776 33.7969 18.3776 32.6693C18.3776 31.5417 17.4635 30.6276 16.3359 30.6276C15.2084 30.6276 14.2943 31.5417 14.2943 32.6693C14.2943 33.7969 15.2084 34.7109 16.3359 34.7109ZM24.5026 34.7109C25.6302 34.7109 26.5443 33.7969 26.5443 32.6693C26.5443 31.5417 25.6302 30.6276 24.5026 30.6276C23.375 30.6276 22.4609 31.5417 22.4609 32.6693C22.4609 33.7969 23.375 34.7109 24.5026 34.7109ZM34.7109 32.6693C34.7109 33.7969 33.7969 34.7109 32.6693 34.7109C31.5417 34.7109 30.6276 33.7969 30.6276 32.6693C30.6276 31.5417 31.5417 30.6276 32.6693 30.6276C33.7969 30.6276 34.7109 31.5417 34.7109 32.6693Z" fill="white" />
                                </svg>
                                <Upgrade class="upgrade-button" User={account} />
                            </div>
                        </div>}
                        <h3 className={`${account?.subscription === "free" ? "overlay-title" : ""}`}>{t("2D to 3D Floor Plan")}</h3>
                        <button className={`try back-FF8800 ${account?.subscription === "free" ? "overlay-button" : ""}`} onClick={() => UpdateFeatureHandler("2D to 3D Floor Plan")}>{t("Try Now")}</button>
                        <span className='credit-number'>100 {t("Credits")}</span>

                    </div>
                </div>
                <div className='col-md-3 mb-3'>
                    <div className='card-body back-DCF4FF'>
                        {account?.subscription === "free" && <div className="overlay">
                            <div className="overlay-content">
                                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7214 20.5308V16.3359C10.7214 8.72476 16.8914 2.55469 24.5026 2.55469C32.1138 2.55469 38.2839 8.72476 38.2839 16.3359V20.5308C40.5598 20.7007 42.0419 21.1299 43.1253 22.2132C44.9193 24.0072 44.9193 26.8946 44.9193 32.6693C44.9193 38.444 44.9193 41.3313 43.1253 43.1253C41.3313 44.9193 38.444 44.9193 32.6693 44.9193H16.3359C10.5612 44.9193 7.67388 44.9193 5.87991 43.1253C4.08594 41.3313 4.08594 38.444 4.08594 32.6693C4.08594 26.8946 4.08594 24.0072 5.87991 22.2132C6.96328 21.1299 8.4454 20.7007 10.7214 20.5308ZM13.7839 16.3359C13.7839 10.4161 18.5828 5.61719 24.5026 5.61719C30.4224 5.61719 35.2214 10.4161 35.2214 16.3359V20.4266C34.4394 20.4193 33.5911 20.4193 32.6693 20.4193H16.3359C15.4141 20.4193 14.5658 20.4193 13.7839 20.4266V16.3359ZM16.3359 34.7109C17.4635 34.7109 18.3776 33.7969 18.3776 32.6693C18.3776 31.5417 17.4635 30.6276 16.3359 30.6276C15.2084 30.6276 14.2943 31.5417 14.2943 32.6693C14.2943 33.7969 15.2084 34.7109 16.3359 34.7109ZM24.5026 34.7109C25.6302 34.7109 26.5443 33.7969 26.5443 32.6693C26.5443 31.5417 25.6302 30.6276 24.5026 30.6276C23.375 30.6276 22.4609 31.5417 22.4609 32.6693C22.4609 33.7969 23.375 34.7109 24.5026 34.7109ZM34.7109 32.6693C34.7109 33.7969 33.7969 34.7109 32.6693 34.7109C31.5417 34.7109 30.6276 33.7969 30.6276 32.6693C30.6276 31.5417 31.5417 30.6276 32.6693 30.6276C33.7969 30.6276 34.7109 31.5417 34.7109 32.6693Z" fill="white" />
                                </svg>
                                <Upgrade class="upgrade-button" User={account} />
                            </div>
                        </div>
                        }
                        <h3 className={`${account?.subscription === "free" ? "overlay-title" : ""}`}>{t("Style Transfer")}</h3>
                        <button className={`try back-0099DE ${account?.subscription === "free" ? "overlay-button " : ""}`} onClick={() => UpdateFeatureHandler("Style Transfer")}>{t("Try Now")}</button>
                        <span className='credit-number'>50 {t("Credits")}</span>

                    </div>
                </div>
            </div>

            <GenerateImages Feature={Feature} onValueChange={updateChangetValue} change={change} User={account !== "" ? account : ""} />

            <div className='row m-100'>
                <div className='col-md-12 d-flex align-items-center align-self-center justify-content-center'>
                    <p className='Copyrights'>{t("Copyrights © 2024  All rights reserved")} <span>{t("Coralytics")}</span></p>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        height: '100vh',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" className=' pdd-20-24'>
                    <div className='title-dialog'>
                        <h4 className='justify-content-start'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2765_34051)">
                                    <path d="M17.5138 3.87156L15.7337 2.10156L5.84375 12.0016L15.7437 21.9016L17.5138 20.1316L9.38375 12.0016L17.5138 3.87156Z" fill="black" fillOpacity="0.87" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2765_34051">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            {t("Subscription")} </h4>
                        <div className='close justify-content-end' onClick={handleClose}>
                            <svg width="24" height="24" className='svg-end' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 17.9999L17.9999 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.9999 17.9999L6 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <UpgradePrice User={account} />
                </DialogContent>
                <DialogActions className='pdd-20-24'>
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default Dashboard;