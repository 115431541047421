import React, { useEffect, useRef, useState } from 'react';
import { DownloadEnhancedImage, FloorPlanService, GenerateImage, GenerateStyleTransfer, GetDesigner, GetItemsRecommendation } from '../../../Services/Genertae';
import { useLocation, useNavigate } from 'react-router-dom';
import { Generate } from '../../../Models/Generate';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import { isLogged } from '../../../Services/Authentication';
import DialogTitle from '@mui/material/DialogTitle';
import Upgrade from '../Upgrade';
import Recommendations from './Recommendations';
import UploadImages from './upload-images';
import Result from './result';
import InteriorDesigner from './InteriorDesigner';
import { Designer } from '../../../Models/Designer';
import { useTranslation } from 'react-i18next';

const GenerateImages: React.FunctionComponent<{ Feature: any, onValueChange: any, change: any, User: any }> = (props) => {

	const [generateValue, setGenerateValue] = useState<Generate>({ environment: "", room_type: "", style: "", images: [], uploaded: false, type: "", style_image: [] });
	const [isSuccess, setIsSuccess] = useState<any>(false);
	const [isResult, setIsResult] = useState<any>(false);
	const [count, setCount] = useState<any>(0);
	const [generatedimages, setGeneratedimages] = useState<any>([]);
	const [isSpinner, setIsSpinner] = useState<boolean>(false);
	const [isSpinnerRec, setIsSpinnerRec] = useState<boolean>(false);
	const [isSpinnerDes, setIsSpinnerDes] = useState<boolean>(false);
	const [showMsgUpgrade, setShowMsgUpgrade] = useState<any>("");
	const { t } = useTranslation();
	const [designer, setDesigner] = useState<Designer>({});
	const [open, setOpen] = React.useState(false);
	const [listShop, setListShop] = useState<any>([]);
	const navigate = useNavigate();

	useEffect(() => {
		setIsResult(false)
		setGenerateValue({ environment: "", room_type: "", style: "", images: [], uploaded: false, type: "", style_image: [] })
	}, [props.Feature, open]);

	const handleClose = () => {
		setOpen(false);
	}

	const Generate3DPlanHandler = (value: any) => {
		if (!isLogged()) {
			return navigate('/sign-up');
		}
		setIsResult(true)
		setIsSpinner(true)
		setIsSuccess(false)
		setGeneratedimages([])
		FloorPlanService(value).then((resp) => {
			setGeneratedimages(resp.result);
			setIsSpinner(false)
			setIsSpinnerDes(false)
			GetDesigner("").then((response) => {
				setIsSpinnerDes(false)
			
				setDesigner(response)
			}).catch(error => {
				setIsSpinner(false)
			})
		}).catch((error) => {
			if (error.response.status === 401) {
				setIsSpinner(false)
				setShowMsgUpgrade("You Dont have enough credits score")
				setOpen(true)
			} else {
				setIsSpinner(false)
				setShowMsgUpgrade("Failed to generate image")
				setOpen(true)
			}
		})
	}

	const GenerateRoomHandler = (value: any) => {

		if (!isLogged()) {
			return navigate('/sign-up');
		}
		setCount(count + 1)
		setIsResult(true)
		setIsSpinner(true)
		setIsSuccess(false)
		setGeneratedimages([])
		setGenerateValue({
			...value,
			uploaded: false
		});
		GenerateImage(value).then((resp) => {
			props.onValueChange(!props.change)
			setGeneratedimages(resp.result);
			setIsSpinner(false)
			setIsSuccess(true)
			setIsSpinnerRec(true)
			setIsSpinnerDes(false)
			GetDesigner("").then((response) => {
				setIsSpinnerDes(false)
				setDesigner(response[0])
			}).catch(error => {
				setIsSpinner(false)
			})
			GetItemsRecommendation(resp.result).then((response) => {
				console.log(response)
				setIsSpinnerRec(false)
				setListShop(response)
			}).catch(error => {
				setIsSpinner(false)
			})

		}).catch((error) => {
			if (error.response.status === 401) {
				setIsSpinner(false)
				setShowMsgUpgrade("You Dont have enough credits score")
				setOpen(true)
			} else {
				setIsSpinner(false)
				setShowMsgUpgrade("Failed to generate image")
				setOpen(true)
			}
		})
	}

	const GenerateRoomStyleHandler = (value: any) => {
		if (!isLogged()) {
			return navigate('/sign-up');
		}
		setIsResult(true)
		setIsSpinner(true)
		setIsSuccess(false)
		setGeneratedimages([])
		setGenerateValue({
			...value,
			uploaded: false
		});
		GenerateStyleTransfer(value).then((resp) => {
			props.onValueChange(!props.onValueChange)
			setGeneratedimages(resp.result);
			setIsSpinner(false)
			setIsSpinnerRec(true)
			setIsSpinnerDes(false)
			GetDesigner("").then((response) => {
				setIsSpinnerDes(false)
				setDesigner(response)
			}).catch(error => {
				setIsSpinner(false)
			})
			GetItemsRecommendation(resp.result).then((response) => {
				console.log(response)
				setIsSpinnerRec(false)
				setListShop(response)
			}).catch(error => {
				setIsSpinner(false)
			})
		}).catch((error) => {
			if (error.response.status === 401) {
				setIsSpinner(false)
				setShowMsgUpgrade("You Dont have enough credits score")
				setOpen(true)
			} else {
				setIsSpinner(false)
				setShowMsgUpgrade("Failed to generate image")
				setOpen(true)
			}
		})
	}

	return (
		<>
			{isResult === false ? <>
				<UploadImages count={count} setCount={setCount} Feature={props.Feature} generateValue={generateValue} onGenerate3DPlan={Generate3DPlanHandler} OnGenerateRoom={GenerateRoomHandler} OnGenerateRoomStyle={GenerateRoomStyleHandler} />
			</> : <>
				<h4 className='title-shop mb-4 m-30'>
					<span onClick={() => { setIsResult(false) }}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M18.5 12.5L5.5 12.5M5.5 12.5L9.875 16.5M5.5 12.5L9.875 8.5" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</span>
					{t("Result")}
				</h4>
				{isSpinner && <div className='' style={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center" }}>
					<Box sx={{ display: 'flex' }}>
						<CircularProgress color="success" />
					</Box>
				</div>}

				<Result generatedimages={generatedimages} />

				<InteriorDesigner isSuccess={isSuccess} isSpinnerDes={isSpinnerDes} designer={designer} />

				<Recommendations listShop={listShop} isSuccess={isSuccess} isSpinnerRec={isSpinnerRec} />
			</>}

			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth={"sm"}
				fullWidth={true}
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-title" className=' pdd-20-24'>
					<div className='title-dialog'>
						<h4 className='justify-content-start'>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#101828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M12 7H12.009" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M12 11V16" stroke="#101828" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
							{t("Upgrade")}</h4>
						<div className='close justify-content-end' onClick={handleClose}>
							<svg width="24" height="24" className='svg-end' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M6 17.9999L17.9999 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
								<path d="M17.9999 17.9999L6 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						<p className='p-dialog'>{t(`${showMsgUpgrade}`)}</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions className='pdd-20-24'>
					<Button onClick={handleClose} id="button-cancel">{t("Cancel")}</Button>
					<Upgrade class={"button-upg"} User={props.User} />
				</DialogActions>
			</Dialog>

		</>
	)
}

export default GenerateImages;