import React from 'react';
import { useTranslation } from 'react-i18next';

const Features: React.FunctionComponent<{}> = (props) => {

	const { t, i18n } = useTranslation();
	const getImagePath = (fileName: any) => {
		return `/assets/images/${i18n.language}/${fileName}`;
	};

	return (
		<div className="slider9 dir-rtl" id="Features">
			<div className="container">
					<div className="caption text-center sec-9-title">
						<h1 >{t("Key Features")}</h1>
					</div>
					<div>
						<div className="row justify-content-center m-50">
							<div className="col-md-4  mb-4">
								<div className='card-slider9'>
									<div className="img-holder">
										<img src={getImagePath("image.webp")} />
									</div>
									<div className="txt-holder">
										<h3>{t("AI Room Staging")}</h3>
										<p>{t("Redesign interiors effortlessly")}</p>
									</div>
									<div className="btn-holder">
										<a className="btn-1" href="#Room Staging">{t("Learn more")}</a>
									</div>
								</div>
							</div>

							<div className="col-md-4 mb-4">
								<div className='card-slider9'>
									<div className="img-holder">
										<img src={getImagePath("image-1.webp")} />
									</div>
									<div className="txt-holder">
										<h3>{t("Garden Landscaping")}</h3>
										<p>{t("Create your dream")}</p>
									</div>
									<div className="btn-holder">
										<a className="btn-1" href="#Garden">{t("Learn more")}</a>
									</div>
								</div>
							</div>

							{/* <div className="col-md-4 mb-4">
								<div className='card-slider9'>
									<div className="img-holder">
										<img src={getImagePath("image-2.webp")} />
									</div>
									<div className="txt-holder">
										<h3>{t("Exterior Redesign")}</h3>
										<p>{t("Revamp your home's exterior.")}</p>
									</div>
									<div className="btn-holder">
										<a className="btn-1"  href="#Exterior">{t("Learn more")}</a>
									</div>
								</div>
							</div> */}
							
							<div className="col-md-4 mb-4">
								<div className='card-slider9'>
									<div className="img-holder">
									<img src={getImagePath("image-4.webp")} />
									</div>
									<div className="txt-holder">
										<h3>{t("2D to 3D Floor Plan")}</h3>
										<p>{t("Visualize your space in 3D")}</p>
									</div>
									<div className="btn-holder">
										<a className="btn-1" href="#3D">{t("Learn more")}</a>
									</div>
								</div>
							</div>

							<div className="col-md-4 mb-4">
								<div className='card-slider9'>
									<div className="img-holder">
									<img src={getImagePath("image-3.webp")} />
									</div>
									<div className="txt-holder">
										<h3>{t("Style Transfer")}</h3>
										<p>{t("Apply any style by uploading")}</p>
									</div>
									<div className="btn-holder">
										<a className="btn-1" href="#Style">{t("Learn more")}</a>
									</div>
								</div>
							</div>

							
						</div>
					</div>
				</div>
		</div>
	)
}
export default Features;