import React from 'react';
import Features from './Features';
import Pricing from './pricing';
import CommonQuestions from './common-questions';
import { useTranslation } from "react-i18next";
import Testimonials from './Testimonials';
import { useNavigate } from 'react-router-dom';
import FeaturesDetails from './features-details';

const Home: React.FunctionComponent<{}> = (props) => {

      const { t, i18n } = useTranslation();
      const navigate = useNavigate();

      const getImagePath = (fileName: any) => {
            return `/assets/images/${i18n.language}/${fileName}`;
      };

      return (
            <>
                  <div className="padd-20rem main--slider slider2 dir-rtl" id="Home">
                        <div className="slide bg-full">
                              <div className="container">
                                    <div className="caption caption-marg text-center">
                                          {/* <span className="button-title">{t("Design Any Room AI")}</span> */}
                                          <h1>{t("Transform Your Home")} <span>{t("DesignAnyRoom AI")}</span></h1>
                                          <p>{t("Redesign, furnish, landscape, and convert")}
                                          </p>
                                          <div className="btn-holder">
                                                <button className="btn-2 marg-10" onClick={() => navigate('/Dashboard')}>{t("GetStarted for Free")}</button>
                                          </div>
                                    </div>
                                    <div className="image-container">
                                          <img src={getImagePath("Frame 2147224930.webp")} alt="after" />
                                    </div>
                              </div>
                        </div>
                  </div>

                  <Features />

                  <FeaturesDetails />

                  <div className="work-sec container dir-rtl">
                        <div className="txt-holder text-center">
                              <h2 className="title-section mb-3">{t("How It Works")}</h2>
                        </div>
                        <div className="row d-flex align-items-center mt-40">
                              <div className="col-md-6">
                                    <img src="/assets/images/Group 1410087826.webp" className='img-fluid' alt="re-design" />
                              </div>
                              <div className="col-md-6">
                                    <div className="txt-holder marg-left-40">
                                          <h2 className="title-section mb-3 mt-4 font-24">{t("Upload a Photo")}</h2>
                                          <p>{t("Upload aphoto of your interior")}</p>
                                    </div>
                              </div>
                        </div>
                        <div className="row d-flex align-items-center mt-40">
                              <div className="col-md-6 order-11">
                                    <div className="txt-holder marg-right-40">
                                          <h2 className="title-section mb-3 mt-4  font-24">{t("Choose or Upload a Style")}</h2>
                                          <p>{t("Select from over 50 styles")}</p>
                                    </div>
                              </div>
                              <div className="col-md-6 order-00">
                                    <img src="/assets/images/Group 14100878262.webp" className='img-fluid' alt="re-design" />
                              </div>
                        </div>
                        <div className="row d-flex align-items-center mt-40">
                              <div className="col-md-6">
                                    <img src="/assets/images/Group 14100878263.webp" className='img-fluid' alt="re-design" />
                              </div>
                              <div className="col-md-6">
                                    <div className="txt-holder marg-left-40">
                                          <h2 className="title-section mb-3 mt-4  font-24">{t("Transform Your Space")}</h2>
                                          <p>{t("Letour AI work its magic")}</p>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <Pricing />

                  <Testimonials />

                  <CommonQuestions />

                  <div className="section-end dir-rtl">
                        <div className='container '>
                              <h2 className="text-center">{t("Ready to Transform")}</h2>
                              <p className="text-center mt-4">{t("Experience the future")}</p>
                              <div className="btn-holder mt-4 text-center">
                                    <button className="btn-2 marg-10 mb-3" onClick={() => navigate('/Dashboard')}>{t("Get Started for Free")}</button>
                                    <button className="btn-1 marg-10 back-white mb-3" onClick={() => {
                                          window.location.hash = '#Pricing';
                                    }}>{t("View Pricing")}</button>
                              </div>
                        </div>
                  </div>

            </>
      )
}
export default Home;
