import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const GenerateImage = async (obj: any) => {
  const apiUrl = Url + "/generate_images";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const GetHistory = async () => {
  const apiUrl = Url + "/history";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const GetItemsRecommendation = async (obj: any) => {
  const apiUrl = Url + "/items_recommendation";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const GetStyle = async (style: any, type: any, page :any) => {
  const apiUrl = Url + "/getStyle";
  const data = type === "Garden Landscaping" ? { style: style, type: "garden", page:page, perPage: 15 } : { style: style, page:page, perPage: 15 };
  const response = await axios.post(apiUrl, data, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const GetDesigner = async (id: any) => {
  const apiUrl = Url + "/profiles";
  const response = await axios.get(`${apiUrl}/${id}`, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const DownloadEnhancedImage = async (obj: any) => {
  const apiUrl = Url + "/download_enhanced_image";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const GenerateStyleTransfer = async (Obj: any) => {
  const apiUrl = Url + "/transfer_style";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}

export const FloorPlanService = async (generateObj: any) => {
  const apiUrl = Url + "/2dto3d";
  const response = await axios.post(apiUrl, generateObj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("token_user")
    }
  })
  return response.data;
}
