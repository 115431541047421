import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../Services/Authentication";
import { useTranslation } from "react-i18next";

const Login: React.FunctionComponent<{}> = (props) => {


    const [formData, setFormData] = useState<any>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<any>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            login(formData).then(res => {
                navigate('/Dashboard')
                window.location.reload()
            }).catch(err => {
                console.log(err.response.data.loginValue.message)
                setErrorMessage(err.response.data.loginValue.message);
            })
            setTimeout(() => {
                setErrorMessage("");
            }, 3000)
        }
    }

    return (
        <div className="padd-80 dir-rtl">
            <div className="login-sec" >
                <div className="container">
                    <div className="row signup-form marg-login">
                        <div className="col-md-7 order-11">
                            <form className=" text-center" onSubmit={handleLogin}>
                                <h2>{t("Login to")} <span className="clr">{t("DesignAnyRoom")}</span></h2>
                                {errorMessage && <div className="alert alert-danger" role="alert">{t(`${errorMessage}`)}</div>}
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder={t("Email Address")} value={formData.email}
                                        onChange={handleChangeEmail} />
                                    {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder={t("Password")} value={formData.password}
                                        onChange={handleChangePassword} />
                                    {errors.password && <span className="error">{t(`${errors.password}`)}</span>}
                                </div>
                                <div style={{ marginTop: "50px" }}>
                                    <button type="submit" className="btn text-center text-uppercase">{t("LOGIN")}</button>
                                    {/* <a href="#">Forgot Password?</a> */}
                                </div>
                                <div className="sign-a">
                                    <span>{t("Don't have an account ? ")}<a href="/sign-up"  onClick={() => { localStorage.removeItem('subscription'); }}>{t("SIGN UP")}</a></span>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-5 d-flex align-items-center order-00">
                            <img src="/assets/images/image pc.png" alt="contact" className="img-login" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;