import React from 'react';
import { useTranslation } from "react-i18next";

const TermsOfService: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();
   

    return (
        <>
            <div className=" dir-rtl terms">
                <div className="container">
                    <div className="text-center">
                        <h1 className='h1-title'>{t("Terms of Service")}</h1>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <h2 className="title mt-4">{t("Effective Date:")} 21/11/2024</h2>
                            <p className='desc'>{t("Welcome to DesignAnyRoom! These Terms")}</p>
                            <h2 className="title mt-4">1. {t("Use of the Platform")}</h2>
                            <p className='desc'>1.1 {t("You must be at least 18 years old")}</p>
                            <p className='desc'>1.2 {t("You agree to use the platform only")}</p>
                            <h2 className="title mt-4">2. {t("Services Offered")}</h2>
                            <p className='desc'> 2.1 {t("Our platform provides AI-powered design")}</p>
                            <h2 className="title mt-4">3. {t("Account Responsibilities")}</h2>
                            <p className='desc'> 3.1 {t("You are responsible for maintaining")}</p>
                            <p className='desc'> 3.2 {t("You agree to notify us immediately")}</p>
                            <h2 className="title mt-4">4. {t("Intellectual Property")}</h2>
                            <p className='desc'>4.1 {t("All content, features, and functionalities")}</p>
                            <p className='desc'>4.2 {t("You may not reproduce, distribute")}</p>
                            <h2 className="title mt-4">5. {t("Payment and Subscription")}</h2>
                            <p className='desc'> 5.1 {t("Our pricing is based on subscription")}</p>
                            <p className='desc'> 5.2 {t("Payments are processed securely through")}</p>
                            <h2 className="title mt-4">6. {t("Disclaimers")}</h2>
                            <p className='desc'>6.1 {t("Our AI results are based on the photos")}</p>
                            <p className='desc'>6.2 {t("The platform is provided “as is”")}</p>
                            <h2 className="title mt-4">7. {t("Limitation of Liability")}</h2>
                            <p className='desc'>7.1 {t("Coralytics is not liable for indirect")}</p>
                            <h2 className="title mt-4">8. {t("Termination")}</h2>
                            <p className='desc'> 8.1 {t("We may suspend or terminate")}</p>
                            <h2 className="title mt-4">9. {t("Governing Law")}</h2>
                            <p className='desc'>9.1 {t("These Terms are governed")}</p>
                            <h2 className="title mt-4">10. {t("Contact")}</h2>
                            <p className='desc'>{t("For inquiries, contact")} <a href="mailto:operations@coralytics.com">operations@coralytics.com </a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TermsOfService;
