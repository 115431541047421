import React, { useEffect, useRef, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import UpgradePrice from './upgrade-price';
import Header from './header';
import ButtonProfile from './button-profile';
import { useTranslation } from 'react-i18next';

const Head: React.FunctionComponent<{ title: any, User: any }> = (props) => {

    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <div className='row display-mobile'>
                <div className='col-md-8'>
                    <h1 className='title'>{t(`${props.title}`)}</h1>
                </div>
                <div className='col-md-4 flex-upgrade'>
                    <div className='card-upgrade'>
                        <span className='credit'> <img  alt="svg-credit" src="/assets/images/image 202.svg" />{props.User.creditScore ? props.User.creditScore : 0}</span>
                        <button className='Upgrade' onClick={() => setOpen(true)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7445 11.9374L16.9053 10.359C16.9911 9.51684 17.0478 8.96074 17.0033 8.61036L17.0188 8.61046C17.7455 8.61046 18.3346 7.98863 18.3346 7.22157C18.3346 6.45451 17.7455 5.83268 17.0188 5.83268C16.2922 5.83268 15.7031 6.45451 15.7031 7.22157C15.7031 7.56848 15.8235 7.88568 16.0228 8.12909C15.7368 8.31543 15.3628 8.7086 14.8 9.3004L14.8 9.30041L14.8 9.30042C14.3664 9.75634 14.1496 9.98429 13.9077 10.0196C13.7737 10.0392 13.6371 10.019 13.5134 9.96153C13.2901 9.85772 13.1412 9.57591 12.8434 9.01227L11.2736 6.04138C11.0899 5.69368 10.9361 5.40266 10.7975 5.16847C11.3662 4.86219 11.7557 4.23804 11.7557 3.51787C11.7557 2.49512 10.9702 1.66602 10.0013 1.66602C9.03238 1.66602 8.24692 2.49512 8.24692 3.51787C8.24692 4.23804 8.63637 4.86219 9.2051 5.16847C9.06645 5.40267 8.91271 5.69365 8.72897 6.04139L7.15925 9.01228L7.15925 9.01228C6.86144 9.5759 6.71254 9.85772 6.4892 9.96153C6.36546 10.019 6.22891 10.0392 6.0949 10.0196C5.85303 9.98429 5.63623 9.75633 5.20261 9.30041C4.63978 8.70862 4.26583 8.31543 3.97984 8.12909C4.17906 7.88568 4.29955 7.56848 4.29955 7.22157C4.29955 6.45451 3.71045 5.83268 2.98376 5.83268C2.25707 5.83268 1.66797 6.45451 1.66797 7.22157C1.66797 7.98863 2.25707 8.61046 2.98376 8.61046L2.9993 8.61036C2.95483 8.96074 3.01149 9.51684 3.09731 10.359L3.25815 11.9374C3.34742 12.8135 3.42166 13.6472 3.51259 14.3975H16.49C16.5809 13.6472 16.6552 12.8135 16.7445 11.9374Z" fill="#FFA722" />
                            <path d="M9.04697 18.3327H10.9556C13.4433 18.3327 14.6871 18.3327 15.517 17.5486C15.8793 17.2063 16.1086 16.5893 16.2741 15.7864H3.72846C3.89398 16.5893 4.12335 17.2063 4.48558 17.5486C5.31548 18.3327 6.55931 18.3327 9.04697 18.3327Z" fill="#FFA722" />
                        </svg>{t("Upgrade")}</button>
                    </div>
                    <ButtonProfile />
                </div>
            </div>

            <Header title={props.title} User={props.User} />

            <div className='row display-none mb-4'>
                <div className='col-md-12 flex-upgrade justify-content-center'>
                    <div className='card-upgrade'>
                        <span className='credit'> <img className='' alt="" src="/assets/images/image 202.svg" />{props.User.creditScore ? props.User.creditScore : 0}</span>
                        <button className='Upgrade' onClick={() => setOpen(true)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7445 11.9374L16.9053 10.359C16.9911 9.51684 17.0478 8.96074 17.0033 8.61036L17.0188 8.61046C17.7455 8.61046 18.3346 7.98863 18.3346 7.22157C18.3346 6.45451 17.7455 5.83268 17.0188 5.83268C16.2922 5.83268 15.7031 6.45451 15.7031 7.22157C15.7031 7.56848 15.8235 7.88568 16.0228 8.12909C15.7368 8.31543 15.3628 8.7086 14.8 9.3004L14.8 9.30041L14.8 9.30042C14.3664 9.75634 14.1496 9.98429 13.9077 10.0196C13.7737 10.0392 13.6371 10.019 13.5134 9.96153C13.2901 9.85772 13.1412 9.57591 12.8434 9.01227L11.2736 6.04138C11.0899 5.69368 10.9361 5.40266 10.7975 5.16847C11.3662 4.86219 11.7557 4.23804 11.7557 3.51787C11.7557 2.49512 10.9702 1.66602 10.0013 1.66602C9.03238 1.66602 8.24692 2.49512 8.24692 3.51787C8.24692 4.23804 8.63637 4.86219 9.2051 5.16847C9.06645 5.40267 8.91271 5.69365 8.72897 6.04139L7.15925 9.01228L7.15925 9.01228C6.86144 9.5759 6.71254 9.85772 6.4892 9.96153C6.36546 10.019 6.22891 10.0392 6.0949 10.0196C5.85303 9.98429 5.63623 9.75633 5.20261 9.30041C4.63978 8.70862 4.26583 8.31543 3.97984 8.12909C4.17906 7.88568 4.29955 7.56848 4.29955 7.22157C4.29955 6.45451 3.71045 5.83268 2.98376 5.83268C2.25707 5.83268 1.66797 6.45451 1.66797 7.22157C1.66797 7.98863 2.25707 8.61046 2.98376 8.61046L2.9993 8.61036C2.95483 8.96074 3.01149 9.51684 3.09731 10.359L3.25815 11.9374C3.34742 12.8135 3.42166 13.6472 3.51259 14.3975H16.49C16.5809 13.6472 16.6552 12.8135 16.7445 11.9374Z" fill="#FFA722" />
                            <path d="M9.04697 18.3327H10.9556C13.4433 18.3327 14.6871 18.3327 15.517 17.5486C15.8793 17.2063 16.1086 16.5893 16.2741 15.7864H3.72846C3.89398 16.5893 4.12335 17.2063 4.48558 17.5486C5.31548 18.3327 6.55931 18.3327 9.04697 18.3327Z" fill="#FFA722" />
                        </svg>{t("Upgrade")}</button>
                    </div>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        height: '100vh',
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" className=' pdd-20-24'>
                    <div className='title-dialog'>
                        <h4 className='justify-content-start'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2765_34051)">
                                    <path d="M17.5138 3.87156L15.7337 2.10156L5.84375 12.0016L15.7437 21.9016L17.5138 20.1316L9.38375 12.0016L17.5138 3.87156Z" fill="black" fillOpacity="0.87" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2765_34051">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            {t("Subscription")} </h4>
                        <div className='close justify-content-end' onClick={handleClose}>
                            <svg width="24" height="24" className='svg-end' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 17.9999L17.9999 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.9999 17.9999L6 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <UpgradePrice User={props.User} />
                </DialogContent>
                <DialogActions className='pdd-20-24'>

                </DialogActions>
            </Dialog>

        </ >
    )
}

export default Head;