import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

const Recommendations: React.FunctionComponent<{ listShop: any, isSuccess: boolean, isSpinnerRec: boolean }> = (props) => {

    const { t } = useTranslation();


    return (
        <>
            {props.isSuccess && <div id="recod">
                <h2 className='mb-4 m-50'>{t("Furniture Recomendations")}</h2>
                {props.isSpinnerRec ?
                    <div className='' style={{ textAlign: "center", marginBottom: "20px", display: "flex", justifyContent: "center" }}><Box sx={{ display: 'flex' }}>
                        <CircularProgress color="success" />
                    </Box>
                    </div> : props.listShop.data.length !== 0 && <div className='list-shop m-50'>
                        <Swiper
                            spaceBetween={30}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            centeredSlides={false}
                            modules={[Navigation, Mousewheel, Keyboard]}
                            className="mySwiper"
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                // when window width is >= 1024px
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                            }}
                        >
                            {props.listShop && props.listShop && props.listShop.data.map((obj: any, i: any) => {
                                return <div key={i}>
                                    {obj.list_shop && obj.list_shop.map((obj1: any, j: any) => (
                                        <SwiperSlide key={j}>
                                            <div className='card-dash'>
                                                <div className='card-body-dash'>
                                                    <img src={obj1.image} alt="Shop Item" />
                                                    <div className='padd-shop'>
                                                        <img className="logo" src="assets/images/furniture.svg" alt="furniture" />
                                                        <div className='card-445'>
                                                            <div className='h-22'>{t("Type")}</div>
                                                            <div className='h-23'>{obj1.category}</div>
                                                            <div className='h-22'>{t("Price")}</div>
                                                            <div className='h-23'>{obj1.priceCurrency} {obj1.price}</div>
                                                        </div>
                                                        <a className='buy-now' href={obj1.url} target='_blank' rel='noopener noreferrer'>
                                                            {t("Buy Now")}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </div>
                            })}
                        </Swiper>

                    </div>}
                
                {props.listShop.data && props.listShop.data.length == 0 && <p className="msg-empty mt-4"> {t("No Recommendation")}</p>}

            </div >
            }
        </>
    )
}

export default Recommendations;