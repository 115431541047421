import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useNavigate } from 'react-router-dom';

const FeaturesDetails: React.FunctionComponent<{}> = (props) => {

      const { t, i18n } = useTranslation();
      const navigate = useNavigate();

      const getImagePath = (fileName: any) => {
            return `/assets/images/${i18n.language}/${fileName}`;
      };

      return (
            <>
                  <div className="work-sec container dir-rtl" id="Room Staging">
                        <div className="row d-flex align-items-center">
                              <div className="col-md-6 order-11">
                                    <div className="img-holder ">
                                          <img src={getImagePath("Frame 1171275532.webp")} className='img-fluid' alt="re-design" />
                                    </div>
                              </div>
                              <div className='col-md-6 order-00'>
                                    <div className="txt-holder  marg-left-80">
                                          <span className="button-title back-EAF8F3 ">{t("Features")}</span>
                                          <h2 className="title-section mb-3 mt-4">{t("Reimagine Your Interiors")}</h2>
                                          <p>{t("Transform empty or outdated rooms")}</p>
                                          <div className="feature-item mt-3">
                                                <img src="/assets/images/1.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Effortless Redecoration")}</h3>
                                                      <p className='marg-0'>{t("No need for physical furniture")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/2.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Cost-Effective")}</h3>
                                                      <p className='marg-0'>{t("Save on interior design")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/3.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Instant Results")}</h3>
                                                      <p className='marg-0'>{t("Save on interior")}</p>
                                                </div>
                                          </div>
                                          <div className="btn-holder">
                                                <button className="btn-2" onClick={() => navigate('/Dashboard')}>{t("Try For Free")}</button>
                                          </div>
                                    </div>

                              </div>
                        </div>

                  </div>

                  <div className="work-sec container dir-rtl" id="Garden">
                        <div className="txt-holder text-center">
                              <h2 className="title-section mb-3">{t("Design Your Perfect")}</h2>
                              <p>{t("Elevate your outdoor spaces")}</p>
                        </div>
                        <div className="row d-flex align-items-center mt-20">
                              <div className="col-md-5">
                                    <div className="txt-holder">

                                          <div className="feature-item mt-3">
                                                <img src="/assets/images/Group 1686557032.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Personalized Designs")}</h3>
                                                      <p className='marg-0'>{t("Tailor your garden to your")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/Group 1686557033.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Save Time and Money")}</h3>
                                                      <p className='marg-0'>{t("Plan before planting.")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/Group 1686557034.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Enhance Property Value")}</h3>
                                                      <p className='marg-0'>{t("Boost curb appeal")}</p>
                                                </div>
                                          </div>
                                          <div className="btn-holder">
                                                <button className="btn-2" onClick={() => navigate('/Dashboard')} >{t("Try For Free")}</button>
                                          </div>
                                    </div>

                              </div>
                              <div className="col-md-7">
                                    <div className="img-holder ">
                                          <img src={getImagePath("Group 1686557029.webp")} className='img-fluid' alt="re-design" />
                                    </div>
                              </div>
                        </div>

                  </div>

                  <div className="work-sec  back-F2FBF8 dir-rtl" id="Exterior">
                        <div className='container'>
                              <div className="row d-flex align-items-center">
                                    <div className="col-md-7 order-11">
                                          <div className="img-holder ">
                                                <img src="/assets/images/Group 3.webp" className='img-fluid' alt="re-design" />
                                          </div>
                                    </div>
                                    <div className='col-md-5 order-00'>
                                          <div className="txt-holder  marg-left-20">
                                                <h2 className="title-section mb-3 mt-4">{t("Give Your Home a Stunning")}</h2>
                                                <p>{t("Revamp your home's exterior")}</p>
                                                <div className="feature-item mt-3">
                                                      <img src="/assets/images/4.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Explore Styles")}</h3>
                                                            <p className='marg-0'>{t("From modern to traditional.")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <img src="/assets/images/5.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Boost Curb Appeal")}</h3>
                                                            <p className='marg-0'>{t("Make a lasting impression.")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <img src="/assets/images/6.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Plan Renovations")}</h3>
                                                            <p className='marg-0'>{t("See results before investing.")}</p>
                                                      </div>
                                                </div>
                                                <div className="btn-holder">
                                                      <button className="btn-2" onClick={() => navigate('/Dashboard')}>{t("Try For Free")}</button>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                        </div>
                  </div>

                  <div className="work-sec container" id="3D">
                        <div className="row d-flex align-items-center">
                              <div className="col-md-6 order-11">
                                    <div className="img-holder ">
                                          <ReactCompareSlider
                                                itemOne={<ReactCompareSliderImage src="/assets/images/image-before.webp" alt="Image one" />}
                                                itemTwo={<ReactCompareSliderImage src="/assets/images/image-after.webp" alt="Image two" />}
                                          />
                                    </div>
                              </div>
                              <div className='col-md-6 order-00 dir-rtl'>
                                    <div className="txt-holder  marg-left-40">
                                          <span className="button-title back-EAF8F3 ">{t("Features")}</span>
                                          <h2 className="title-section mb-3 mt-4">{t("Experience Your Space in 3D")}</h2>
                                          <p>{t("Bring flat floor plans to life")}</p>
                                          <div className="feature-item mt-3">
                                                <img src="/assets/images/1.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Immersive Visualization")}</h3>
                                                      <p className='marg-0'>{t("Understand space dimensions")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/2.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Improved Planning")}</h3>
                                                      <p className='marg-0'>{t("Optimize layouts before")}</p>
                                                </div>
                                          </div>
                                          <div className="feature-item">
                                                <img src="/assets/images/3.svg" alt="features" />
                                                <div>
                                                      <h3 className='marg-0'>{t("Shareable Models")}</h3>
                                                      <p className='marg-0'>{t("Easily present to others")}</p>
                                                </div>
                                          </div>
                                          <div className="btn-holder">
                                                <button className="btn-2" onClick={() => navigate('/Dashboard')}>{t("Try For Free")}</button>
                                          </div>
                                    </div>

                              </div>
                        </div>

                  </div>

                  <div className="work-sec-1 ">
                        <img className="decoration-circles-1" src="/assets/images/Ellipse 228.svg" alt="alternative" />
                        <div className='container'>
                              <div className="row">
                                    <div className='col-md-7 d-flex flex-column justify-content-between dir-rtl'>
                                          <div className="txt-holder marg-right-20">
                                                <span className="button-title back-EAF8F3 ">{t("Interior")}</span>
                                                <h2 className="title-section mb-3 mt-4">{t("Need a Professional")}</h2>
                                                <p>{t("DesignAnyRoom connects you with trusted")}</p>

                                                <div className="btn-holder">
                                                      <button className="btn-2" onClick={() => navigate('/Dashboard')}>{t("Try For Free")}</button>
                                                </div>
                                          </div>
                                          <div className="img-holder d-flex justify-content-end">
                                                <img src="/assets/images/portrait-serious-young.webp" className='img-int-1  view-img-mobile' alt="re-design" style={{ maxWidth: '100%', height: 'auto' }}
                                                />
                                          </div>
                                    </div>
                                    <div className="col-md-5 d-flex justify-content-start ">
                                          <div className="img-holder view-img-mobile ">
                                                <img src="/assets/images/Rectangle 4102.webp" className='img-int-2 mb-3' alt="re-design" />
                                                <img src="/assets/images/rectangle 572.webp" className='img-int-3' alt="re-design" />
                                          </div>
                                          <img src="/assets/images/Frame 214722520575.webp" className='view-img-web img-inter' alt="re-design" />
                                    </div>
                              </div>
                        </div>
                        <img className="decoration-circles-2" src="/assets/images/Ellipse 229.svg" alt="alternative" />
                  </div>

                  <div className="work-sec back-F2FBF8 dir-rtl" id="Style">
                        <div className='container'>
                              <div className="txt-holder text-center">
                                    <h2 className="title-section mb-3">{t("Floor Plan & Style Transfer")}</h2>
                                    <p>{t("Upload any style inspiration image")}</p>
                              </div>
                              <div className="row d-flex align-items-center mt-20">
                                    <div className="col-md-4">
                                          <div className="txt-holder">

                                                <div className="feature-item mt-3">
                                                      <img src="/assets/images/7.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Upload Style ")}</h3>
                                                            <p className='marg-0'>{t("Upload a photo of your preferred style")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <img src="/assets/images/8.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Apply Style")}</h3>
                                                            <p className='marg-0'>{t("Apply the uploaded style to your room")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <img src="/assets/images/9.svg" alt="features" />
                                                      <div>
                                                            <h3 className='marg-0'>{t("Easy to Use")}</h3>
                                                            <p className='marg-0'>{t("Simple upload and apply")}</p>
                                                      </div>
                                                </div>
                                                <div className="btn-holder">
                                                      <button className="btn-2" onClick={() => navigate('/Dashboard')}>{t("Try For Free")}</button>
                                                </div>
                                          </div>

                                    </div>
                                    <div className="col-md-8">
                                          <div className="img-holder ">
                                                <img src={getImagePath("Mask group.webp")} className='img-fluid' alt="re-design" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

            </>
      )
}
export default FeaturesDetails;
