import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Testimonials: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [activeUser, setActiveUser] = useState('user2');

    const [testimonials, setTestimonials] = useState<any>({
        user1: {
            name: "Meriem L.",
            role: "Interior Designer",
            img: "/assets/images/user-3.webp",
            text: "AI staging turned my ideas into",
        },
        user2: {
            name: "Daniel A.",
            role: "Property Agent",
            img: "/assets/images/user-2.svg",
            text: "Design Any Room AI transformed",
        },
        user3: {
            name: "Klara J.",
            role: "Property Agent",
            img: "/assets/images/user-1.webp",
            text: "As a realtor, this AI staging tool",
        }
    })

    const handleClick = (userId: any) => {
        setActiveUser(userId);
    };

    return (
        <div className="work-sec service-community back-EEF9F5 " id="Testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 >{t("Testimonials")}</h2>
                    </div>
                </div>
                <div className='row mt-20'>
                    <div className='col-md-5 order-11 dir-rtl'>
                        <div className="user-list">
                            {Object.keys(testimonials).map((userId) => (
                                <div
                                    key={userId}
                                    className={`user ${activeUser === userId ? 'active' : ''}`}
                                    onClick={() => handleClick(userId)}
                                >
                                    <img src={testimonials[userId].img} alt={t(testimonials[userId].name)} className="user-img" />
                                    <div className="user-info">
                                        <p className="user-name font-color">{t(testimonials[userId].name)}</p>
                                        <p className="user-role font-color">{t(testimonials[userId].role)}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-md-7  d-flex align-items-center order-00 dir-rtl'>
                        <div className="row testimonial">
                            <div className='col-2'>
                                <svg width="50" height="38" viewBox="0 0 50 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M39.5519 13.9763C39.4512 12.8296 39.5282 9.71244 42.3633 5.37782C42.5777 5.05072 42.5388 4.60957 42.2725 4.32913C41.1166 3.11168 40.401 2.34343 39.8991 1.80584C39.2391 1.09701 38.9377 0.774028 38.4967 0.352876C38.2023 0.0740825 37.7567 0.0692279 37.4591 0.342508C32.5176 4.87158 27.029 14.2302 27.8232 25.696C28.2886 32.4285 32.9509 37.3152 38.9088 37.3152C45.0229 37.3152 49.9973 32.0765 49.9973 25.6365C49.9973 19.4239 45.3678 14.3291 39.5519 13.9763ZM38.9088 35.6694C33.7924 35.6694 29.7863 31.4248 29.3812 25.5771C28.4877 12.6817 35.7654 4.30502 37.9642 2.0871C38.1786 2.30969 38.4235 2.57162 38.7844 2.95895C39.2192 3.42503 39.8144 4.0631 40.7146 5.0146C37.273 10.5996 37.9223 14.579 38.2068 15.1496C38.3419 15.4204 38.6188 15.6028 38.9088 15.6028C44.1616 15.6028 48.4348 20.1037 48.4348 25.6365C48.4348 31.1685 44.1616 35.6694 38.9088 35.6694Z" fill="#1C704F" />
                                    <path d="M11.8071 13.9761C11.7056 12.8326 11.7803 9.71783 14.6185 5.37761C14.8321 5.05052 14.7939 4.60937 14.5277 4.32893C13.3741 3.11386 12.6592 2.34644 12.158 1.80885C11.4958 1.09845 11.1936 0.774563 10.7527 0.352671C10.4582 0.0738771 10.0126 0.0698449 9.7151 0.341479C4.77354 4.87055 -0.715834 14.2277 0.0768219 25.6958C0.543775 32.4275 5.20682 37.315 11.1646 37.315C17.2789 37.315 22.2532 32.0763 22.2532 25.6363C22.2532 19.4229 17.6237 14.3273 11.8071 13.9761ZM11.1646 35.6692C6.04917 35.6692 2.04065 31.4246 1.63479 25.576C0.743697 12.6775 8.02135 4.304 10.2201 2.0869C10.4353 2.30949 10.681 2.57306 11.0426 2.96121C11.4767 3.42729 12.071 4.06454 12.9698 5.01439C9.52815 10.6002 10.1774 14.5788 10.462 15.1485C10.5971 15.4194 10.8747 15.6026 11.1646 15.6026C16.4175 15.6026 20.6907 20.1035 20.6907 25.6363C20.6907 31.1683 16.4175 35.6692 11.1646 35.6692Z" fill="#1C704F" />
                                </svg>
                            </div>
                            <div className='col-10'>
                            <p className='blockquote-'>
                                {t(testimonials[activeUser].text)}
                            </p>
                            <div className="stars">
                                <svg width="96" height="18" viewBox="0 0 96 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.7235 6.49025C17.5793 6.04794 17.1968 5.72638 16.736 5.65925L12.0674 4.98088L9.98129 0.765702C9.56823 -0.0677339 8.20305 -0.0677339 7.78999 0.765702L5.70387 4.98088L1.04638 5.65925C0.586814 5.72638 0.204315 6.04906 0.0601279 6.49156C-0.0840593 6.93275 0.035753 7.418 0.369315 7.74294L3.74731 11.0354L2.95043 15.6807C2.87225 16.139 3.0605 16.6034 3.43681 16.8772C3.81443 17.1496 4.31431 17.1852 4.72493 16.9689L8.88517 14.7751L13.0576 16.9689C13.2361 17.063 13.4317 17.1095 13.6259 17.1095C13.8788 17.1095 14.1318 17.03 14.3457 16.8774C14.7222 16.6023 14.9103 16.1392 14.8321 15.6809L14.0365 11.0356L17.4145 7.74312C17.7481 7.41912 17.8677 6.93275 17.7235 6.49025Z" fill="#FFB815" />
                                    <path d="M37.2782 6.49025C37.134 6.04794 36.7515 5.72638 36.2907 5.65925L31.6221 4.98088L29.536 0.765702C29.1229 -0.0677339 27.7577 -0.0677339 27.3447 0.765702L25.2586 4.98088L20.6011 5.65925C20.1415 5.72638 19.759 6.04906 19.6148 6.49156C19.4706 6.93275 19.5904 7.418 19.924 7.74294L23.302 11.0354L22.5051 15.6807C22.4269 16.139 22.6152 16.6047 22.9915 16.8772C23.368 17.1496 23.869 17.1852 24.2796 16.9689L28.4399 14.7751L32.6123 16.9689C32.7908 17.063 32.9863 17.1095 33.1806 17.1095C33.4335 17.1095 33.6865 17.03 33.9004 16.8774C34.2769 16.6023 34.465 16.1392 34.3868 15.6809L33.5912 11.0356L36.9692 7.74312C37.3028 7.41912 37.4224 6.93275 37.2782 6.49025Z" fill="#FFB815" />
                                    <path d="M56.8329 6.49025C56.6887 6.04794 56.3062 5.72638 55.8453 5.65925L51.1768 4.98088L49.0907 0.765702C48.6776 -0.0677339 47.3124 -0.0677339 46.8994 0.765702L44.8132 4.98088L40.1557 5.65925C39.6962 5.72638 39.3137 6.04906 39.1695 6.49156C39.0253 6.93275 39.1451 7.418 39.4787 7.74294L42.8567 11.0354L42.0598 15.6807C41.9816 16.139 42.1699 16.6034 42.5462 16.8772C42.9238 17.1496 43.4237 17.1852 43.8343 16.9689L47.9944 14.7751L52.1668 16.9689C52.3453 17.063 52.5408 17.1095 52.7351 17.1095C52.988 17.1095 53.241 17.03 53.4549 16.8774C53.8314 16.6023 54.0195 16.1392 53.9413 15.6809L53.1457 11.0356L56.5237 7.74312C56.8575 7.41912 56.9771 6.93275 56.8329 6.49025Z" fill="#FFB815" />
                                    <path d="M76.3876 6.49025C76.2434 6.04794 75.8609 5.72638 75.4 5.65925L70.7315 4.98088L68.6454 0.765702C68.2323 -0.0677339 66.8671 -0.0677339 66.4541 0.765702L64.3679 4.98088L59.7104 5.65925C59.2509 5.72638 58.8684 6.04906 58.7242 6.49156C58.58 6.93275 58.6998 7.418 59.0334 7.74294L62.4114 11.0354L61.6145 15.6807C61.5363 16.139 61.7246 16.6034 62.1009 16.8772C62.4785 17.1496 62.9784 17.1852 63.389 16.9689L67.549 14.7751L71.7215 16.9689C71.9 17.063 72.0955 17.1095 72.2898 17.1095C72.5427 17.1095 72.7957 17.03 73.0096 16.8774C73.3861 16.6023 73.5742 16.1392 73.496 15.6809L72.7004 11.0356L76.0784 7.74312C76.412 7.41912 76.5318 6.93275 76.3876 6.49025Z" fill="#FFB815" />
                                    <path d="M95.9384 6.49025C95.7929 6.04794 95.4117 5.72638 94.9508 5.65925L90.2823 4.98088L88.1961 0.765702C87.7831 -0.0677339 86.4179 -0.0677339 86.0048 0.765702L83.9187 4.98088L79.2612 5.65925C78.8017 5.72638 78.4192 6.04906 78.275 6.49156C78.1308 6.93275 78.2506 7.418 78.5842 7.74294L81.9622 11.0354L81.1653 15.6807C81.0871 16.139 81.2753 16.6034 81.6517 16.8772C81.8643 17.0311 82.1161 17.1093 82.369 17.1093C82.5646 17.1093 82.7602 17.0628 82.9409 16.9687L87.1011 14.7749L91.2747 16.9687C91.6853 17.1875 92.1839 17.1507 92.5617 16.8759C92.9369 16.6021 93.1251 16.139 93.0469 15.6805L92.2514 11.0352L95.6294 7.74275C95.9627 7.41913 96.0814 6.93275 95.9384 6.49025Z" fill="#FFB815" />
                                </svg>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;