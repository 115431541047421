import './App.css';
import PublicRouter from './Routes/PublicRouter';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';


const App: React.FunctionComponent<{}> = (props) => {
  return (
    <HelmetProvider>
      <PublicRouter />
    </HelmetProvider>
  );
}
export default App;
