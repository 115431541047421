import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import axios from 'axios';
const Url = process.env.REACT_APP_URl;

function VerifyAccount() { 
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Verifying...");
  const [isVerified, setIsVerified] = useState(false);
  const token = searchParams.get("token");

  useEffect(() => {
    // Verify the account when the component mounts
    const verifyAccount = async () => {
      try {
        const response = await axios.get(`${Url}/verify?token=${token}`);
        if (response.data.message === "Account verified successfully") {
          setMessage("Your account has been verified successfully!");
          setIsVerified(true); // Update state to show the login link
        } else {
          setMessage(response.data.error || "Failed to verify account.");
        }
      } catch (error) {
        setMessage("Verification failed. Please try again.");
      }
    };

    if (token) {
      verifyAccount();
    } else {
      setMessage("Invalid or missing verification token.");
    }
  }, [token]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Account Verification</h2>
      <p>{message}</p>
      {isVerified && (
        <Link to="/login" style={{ marginTop: '20px', display: 'inline-block' }}>
          Go to Login
        </Link>
      )}
    </div>
  );
}

export default VerifyAccount;
