import React, { useEffect, useState } from "react";
import { AccountObj } from "../../Models/Account";
import { CreateAccount } from "../../Services/Authentication";
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Subscription from "./subscription";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Url = process.env.REACT_APP_URl;


const SignUp: React.FunctionComponent<{}> = (props) => {

    const storedSubscription = localStorage.getItem('subscription');
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errors, setErrors] = useState<Partial<AccountObj>>({});
    const [formData, setFormData] = useState<AccountObj>({ subscription: storedSubscription ? JSON.parse(storedSubscription).subscription : null });
    const [subscription, setSubscription] = useState<any>([{ value: "free", type: "Free" }, { value: "basic", type: "Basic" }, { value: "avanced", type: "Avanced" }, { value: "professional", type: "Professional" }]);
    const [listType, setListType] = useState<any>([{ value: "real estate broker", type: "Real estate Broker" }, { value: "interior designer", type: "Interior Designer" }, { value: "home owner", type: "Home Owner" }, { value: "holiday rental", type: "Holiday Rental" }])
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const [user, setUser] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subscription: storedSubscription ? JSON.parse(storedSubscription).subscription : null,
        }));
    }, [formData.email])

    const handleChangeName = (event: any) => {
        const nameValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            name: nameValue,
        }));
    }


    const handleChangeType = (event: SelectChangeEvent) => {
        const type = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            type: type,
        }));
    };

    const handleChangeSubscription = (event: SelectChangeEvent) => {
        const subscription = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            subscription: subscription,
        }));
    };

    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handlePyment = async (value: any, id: any) => {

        const stripe = await loadStripe('pk_live_51PFD6iGzYmczk5yZEgPY10US28UcaFf1YEysZP9SVH0KSeNRGtwt0JtHal3YiXVwq9ZaBOBow5iw6MCs2E62ZwA400A2R7JaDG');
        const { data: { sessionId } } = await axios.post(Url + "/api/create-checkout-session", {
            accountId: id,
            priceId: value
        });
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error(error);
            }
        } else {
            console.error('Stripe has not loaded');
        }
    };


    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleCreate = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (!formData.name) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                name: 'Please enter your name',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        if (!formData.type) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                type: 'Please select Account Type',
            }));
        }
        if (!formData.subscription) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                subscription: 'Please select Subscription',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            CreateAccount(formData).then(resp => {
                if (storedSubscription && JSON.parse(storedSubscription).subscription !== "free") {
                    handlePyment(storedSubscription ? JSON.parse(storedSubscription).priceId : null, resp.account._id)
                    localStorage.removeItem('subscription')
                } else {
                    localStorage.removeItem('subscription')
                    navigate("/login")
                }
            }).catch((error) => {
                setErrorMessage("Failed to create account")
            })
        }
    }


    return (
        <div className="padd-80 " >
            <div className="login-sec">
                <div className="container">

                    {storedSubscription ? <div className="row signup-form  marg-login dir-rtl">
                        <div className="col-md-7 order-11">
                            <form className="text-center" onSubmit={handleCreate}>
                                <h2>{t("SIGN UP")}</h2>
                                {errorMessage && <div className="alert alert-danger" role="alert">{t(`${errorMessage}`)}</div>}
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder={t("Name")} value={formData.name || ""}
                                        onChange={handleChangeName} />
                                    {errors.name && <span className="error">{t(`${errors.name}`)}</span>}
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder={t("Email Address")} value={formData.email || ""}
                                        onChange={handleChangeEmail} />
                                    {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder={t("Password")} value={formData.password || ""}
                                        onChange={handleChangePassword} />
                                    {errors.password && <span className="error">{t(`${errors.password}`)}</span>}
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth sx={{ marginTop: "20px" }}>
                                        <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Account Type' }}
                                            value={formData.type}
                                            className="subsc-left dir-rtl"
                                            onChange={handleChangeType}
                                            sx={{
                                                background: "rgba(255, 255, 255, 0.05)",
                                                padding: "12px 0px 12px",
                                                width: "100%",
                                                color: "#101410",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                borderRadius: "8px",
                                                fontFamily: 'Source Sans Pro',
                                                height: "50px",
                                                textOverflow: "ellipsis",
                                                fontStyle: "normal",
                                                lineHeight: "22px",
                                                "&:hover": {
                                                    borderColor: "#2EBB84"
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                "&.Mui-focused": {
                                                    borderColor: "#2EBB84",
                                                },
                                            }}
                                        >
                                            <MenuItem sx={{
                                                display: "block",
                                                width: "100%",
                                                fontSize: "16px",
                                                color: "#101410",
                                                background: "rgba(255, 255, 255, 0.05)",
                                                fontWeight: "400",
                                                fontFamily: 'Source Sans Pro',
                                                height: "50px",
                                                textOverflow: "ellipsis",
                                                padding: "12px 12px",
                                                fontStyle: "normal",
                                                "&:focus": {
                                                    backgroundColor: "#2EBB84",
                                                    color: "#FFF"
                                                },
                                                "&:hover": {
                                                    backgroundColor: "#2EBB84",
                                                    color: "#FFF"
                                                },
                                                lineHeight: "22px",
                                            }} disabled > {t("Account Type")}</MenuItem>
                                            {listType.map((obj: any, index: any) => {
                                                return <MenuItem key={index} sx={{
                                                    display: "block",
                                                    width: "100%",
                                                    fontSize: "16px",
                                                    color: "#101410",
                                                    background: "rgba(255, 255, 255, 0.05)",
                                                    fontWeight: "400",
                                                    fontFamily: 'Source Sans Pro',
                                                    height: "50px",
                                                    textOverflow: "ellipsis",
                                                    padding: "12px 12px",
                                                    fontStyle: "normal",
                                                    "&:focus": {
                                                        backgroundColor: "#2EBB84",
                                                        color: "#FFF"
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: "#2EBB84",
                                                        color: "#FFF"
                                                    },
                                                    lineHeight: "22px",
                                                }} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>

                                    {errors.type && <span className="error">{errors.type}</span>}
                                </div>
                                <div className="form-group">
                                    <FormControl fullWidth sx={{ marginTop: "20px" }}>
                                        <Select
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Subscription' }}
                                            value={storedSubscription ? JSON.parse(storedSubscription).subscription : null}
                                            className="subsc-left dir-rtl disabled"
                                            onChange={handleChangeSubscription}
                                            sx={{
                                                background: "rgba(255, 255, 255, 0.05)",
                                                padding: "12px 0px 12px",
                                                width: "100%",
                                                color: "#101410",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                borderRadius: "8px",
                                                fontFamily: 'Source Sans Pro',
                                                height: "50px",
                                                textOverflow: "ellipsis",
                                                fontStyle: "normal",
                                                lineHeight: "22px",
                                                "&:hover": {
                                                    borderColor: "#2EBB84"
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#2EBB84',
                                                },
                                                "&.Mui-focused": {
                                                    borderColor: "#2EBB84",
                                                },
                                            }}
                                        >
                                            <MenuItem sx={{
                                                display: "block",
                                                width: "100%",
                                                fontSize: "16px",
                                                color: "#101410",
                                                background: "rgba(255, 255, 255, 0.05)",
                                                fontWeight: "400",
                                                fontFamily: 'Source Sans Pro',
                                                height: "50px",
                                                textOverflow: "ellipsis",
                                                padding: "12px 12px",
                                                fontStyle: "normal",
                                                "&:focus": {
                                                    backgroundColor: "#2EBB84",
                                                    color: "#FFF"
                                                },
                                                "&:hover": {
                                                    backgroundColor: "#2EBB84",
                                                    color: "#FFF"
                                                },
                                                lineHeight: "22px",
                                            }} disabled > {t("Subscription")}</MenuItem>
                                            {subscription.map((obj: any, index: any) => {
                                                return <MenuItem key={index} sx={{
                                                    display: "block",
                                                    width: "100%",
                                                    fontSize: "16px",
                                                    color: "#101410",
                                                    background: "rgba(255, 255, 255, 0.05)",
                                                    fontWeight: "400",
                                                    fontFamily: 'Source Sans Pro',
                                                    height: "50px",
                                                    textOverflow: "ellipsis",
                                                    padding: "12px 12px",
                                                    fontStyle: "normal",
                                                    "&:focus": {
                                                        backgroundColor: "#2EBB84",
                                                        color: "#FFF"
                                                    },
                                                    "&:hover": {
                                                        backgroundColor: "#2EBB84",
                                                        color: "#FFF"
                                                    },
                                                    lineHeight: "22px",
                                                }} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>

                                    {errors.subscription && <span className="error">{errors.subscription}</span>}
                                </div>
                                <div style={{ marginTop: "50px" }}>
                                    <button type="submit" className="btn text-center text-uppercase">{t("SIGN UP")}</button>
                                </div>
                                <div className="sign-a">
                                    <span>{t("Have an account ?")}<a href="/login">{t("LOGIN")}</a></span>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-5 d-flex align-items-center order-00">
                            <img src="/assets/images/image pc.png" alt="contact" className="img-login" />
                        </div>
                    </div>
                        : <div className="row signup-form  marg-login">
                            <Subscription User={user} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default SignUp;