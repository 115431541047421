import React from "react";
import { useTranslation } from "react-i18next";

const Footer: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <footer id="footer">
            <div className="footer-holder dir-rtl">
                <div className="container">
                    <div className="row">
                        <div className=" col-md-4">
                            <img src="/assets/images/Design Any Room  MODIF LOGO BLANC 2.png" className="img-fluid logo-footer" alt="logo" />
                        </div>
                        <div className=" col-md-2">
                            <h3>{t("Quick Links")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a href="/home">{t("Home")}</a></li>
                                <li><a href="/home#Features">{t("Features")}</a></li>
                                <li><a href="/pricing">{t("Pricing")}</a></li>
                                <li><a href="/home#Testimonials">{t("Testimonials")}</a></li>
                                <li><a href="/contact">{t("Contact")}</a></li>
                            </ul>
                        </div>
                        <div className=" col-md-2">
                            <h3>{t("Resources")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a href="terms-of-service">{t("Terms of Service")}</a></li>
                                <li><a href="privacy-policy">{t("Privacy Policy")}</a></li>
                                <li><a href="cancellation-policy">{t("Cancellation Policy")}</a></li>
                            </ul>
                        </div>
                        <div className=" col-md-4">
                            <h3>{t("Contact Us")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a  href="mailto: [contact@designAnyRoom.com]"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="3.25" width="20" height="18" rx="4" stroke="white" strokeWidth="1.5" />
                                    <path d="M2 7.25L9.50122 13.251C10.9621 14.4197 13.0379 14.4197 14.4988 13.251L22 7.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> contact@designAnyRoom.com</a></li>

                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="footer-area dir-rtl">
                <div className="container">
                    <div className="hr-footer"></div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>{t("Design Any Room")}</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer