import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UpgradePrice from "../Dash/upgrade-price";
import { GetAccount, isLogged } from "../../Services/Authentication";
import Subscription from "./subscription";

const Pricing: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();
    const [account, setAccount] = useState<any>("");

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setAccount(resp)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, []);

    return (
        <section className="work-sec service-plan ">
            <div className="container">
                <div className="row dir-rtl">
                    <div className="col-xs-12 text-center header ">
                        <span className="button-title back-EAF8F3 ">{t("Special Discount Offer")}</span>
                        <h2 className="title-section mb-3 mt-4">{t("Choose the plan that fits")}</h2>
                    </div>
                </div>
                <div className=" mt-40">
                    <Subscription User={account !== "" ? account : ""} />
                </div>
            </div>
        </section>

    )
}
export default Pricing;