import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GetAccount, isLogged } from '../../Services/Authentication';
import { useTranslation } from "react-i18next";

const Header: React.FunctionComponent<{}> = () => {

    const { t, i18n } = useTranslation();
    const [langue, setLangue] = useState<any>(localStorage.getItem('language') ? localStorage.getItem('language') : "en");
    let location = useLocation().pathname.split('/');
    const pathname = location[1];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser ]= useState<any>();

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setUser(resp)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(true);
    };

    const changeLanguage = (lng: any) => {
        setIsDropdownOpen(false)
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        setLangue(lng)
        document.body.classList.toggle('rtl', lng === 'ar');
        document.body.classList.toggle('ltr', lng !== 'ar');
    };

    const getSidebar = (event: any) => {
        event.preventDefault();
        $("body").toggleClass("nav-active");
    }

    return (
        <header id="header">
            <div className="header-holder">
                <div className="container">
                    <div className="row" >
                        <div className="col-md-12">
                            <div className="logo">
                                <a href="/home">
                                    <img width="221" height="66" src="/assets/images/logo m.svg" className="img-responsive" alt="logo" />
                                </a>
                            </div>
                            <a onClick={getSidebar} className="nav-opener text-center md-round"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.5 9.04167H3.5C3.02167 9.04167 2.625 8.645 2.625 8.16667C2.625 7.68834 3.02167 7.29167 3.5 7.29167H24.5C24.9783 7.29167 25.375 7.68834 25.375 8.16667C25.375 8.645 24.9783 9.04167 24.5 9.04167Z" fill="#292D32" />
                                <path d="M24.5 14.875H3.5C3.02167 14.875 2.625 14.4783 2.625 14C2.625 13.5217 3.02167 13.125 3.5 13.125H24.5C24.9783 13.125 25.375 13.5217 25.375 14C25.375 14.4783 24.9783 14.875 24.5 14.875Z" fill="#292D32" />
                                <path d="M24.5 20.7083H3.5C3.02167 20.7083 2.625 20.3117 2.625 19.8333C2.625 19.355 3.02167 18.9583 3.5 18.9583H24.5C24.9783 18.9583 25.375 19.355 25.375 19.8333C25.375 20.3117 24.9783 20.7083 24.5 20.7083Z" fill="#292D32" />
                            </svg>
                            </a>
                            <nav id="nav">
                                <ul className="list-unstyled">
                                    <li className={`${pathname == "home" || pathname == "" ? "active" : ""}`}>
                                        <a href="/home">{t("HOME")}</a>
                                    </li>
                                    {/* <li className={`${pathname == "about-us" ? "active" : ""}`}><a href="/about-us">{t("ABOUT US")}</a></li> */}
                                    <li className={`${pathname == "pricing" ? "active" : ""}`}><a href="/pricing">{t("PRICING")}</a></li>
                                    <li className={`${pathname == "contact" ? "active" : ""}`}><a href="/contact">{t("CONTACT US")}</a></li>
                                    <li>
                                        {!isLogged() ? <a className="upgrade" href="login">{t("Log In")}
                                        </a> :
                                            <a href="Dashboard">
                                                <img src={user && user.profile ? user.profile: "/assets/images/user-profile.webp"} className="nav-profile" alt="profile" /></a>}
                                    </li>
                                    <li>
                                        <div className="dropdown">
                                            <button className="drop-lang-us dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" onClick={toggleDropdown}
                                                aria-expanded={isDropdownOpen}>
                                                <svg width="16" height="17" style={{ marginRight: "10px" }} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_4_241)">
                                                        <g clipPath="url(#clip1_4_241)">
                                                            <g clipPath="url(#clip2_4_241)">
                                                                <mask id="mask0_4_241" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
                                                                    <path d="M16 0.0400391H0V16.04H16V0.0400391Z" fill="white" />
                                                                </mask>
                                                                <g mask="url(#mask0_4_241)">
                                                                    <path d="M1.33301 8.03972H14.6663M1.33301 8.03972C1.33301 11.7216 4.31778 14.7064 7.99967 14.7064M1.33301 8.03972C1.33301 4.35782 4.31778 1.37305 7.99967 1.37305M14.6663 8.03972C14.6663 11.7216 11.6816 14.7064 7.99967 14.7064M14.6663 8.03972C14.6663 4.35782 11.6816 1.37305 7.99967 1.37305M7.99967 14.7064C9.66719 12.8808 10.6148 10.5117 10.6663 8.03972C10.6148 5.56774 9.66719 3.19862 7.99967 1.37305M7.99967 14.7064C6.33215 12.8808 5.38451 10.5117 5.33301 8.03972C5.38451 5.56774 6.33215 3.19862 7.99967 1.37305" stroke="#2EBB84" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4_241">
                                                            <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                                        </clipPath>
                                                        <clipPath id="clip1_4_241">
                                                            <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                                        </clipPath>
                                                        <clipPath id="clip2_4_241">
                                                            <rect width="16" height="16" fill="white" transform="translate(0 0.0400391)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                {langue.toUpperCase()}
                                            </button>
                                            <ul className={`dropdown-menu width-drop ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" style={{ margin: "0px" }} onClick={() => changeLanguage('en')}>EN</a></li>
                                                <li><a className="dropdown-item" style={{ margin: "0px" }} onClick={() => changeLanguage('ar')}>AR</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header