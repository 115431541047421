import React, { useEffect, useRef, useState } from 'react';
import { GetAccount, isLogged } from '../../Services/Authentication';
import Head from './head';
import { Designer } from '../../Models/Designer';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GetDesigner } from '../../Services/Genertae';


const Profile: React.FunctionComponent<{}> = (props) => {

    const [account, setAccount] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { t } = useTranslation();
    const [designer, setDesigner] = useState<Designer>()

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setAccount(resp)
                setLoading(false)
            }).catch((error) => {
                console.log("error")
            })
            GetDesigner(id).then((response) => {
                setLoading(false)
				setDesigner(response)
			}).catch(error => {
                console.log("error")
			})
        }
    }, []);


    return loading ? (<div> {t("Loading ...")}</div>) : (

        <div className="page-dash ">
            <Head title={"Designer Profile"} User={account !== "" ? account : ""} />
            <div className='row m-50  mb-4 dir-rtl'>
                <div className='col-md-12'>
                    <div className='card border-10'>
                        <img src="/assets/images/Rectangle 34624367.png" className="backg-profile" alt="profile" />
                        <div className='card-des-234'>
                            <img src={designer?.profileimage} className="avatar-23" alt="Designer" />
                            <div className='row padd-profile'>
                                <div className='col mb-2'>
                                    <h3 className='h-25 user-25'>{designer?.name}</h3>
                                    <h3 className='user-type'>{designer?.role}</h3>
                                </div>
                                <div className='col mb-2 d-flex justify-content-end'>
                                    <button className='buy-now width-auto-marg-right'>{t("Contact")}</button>
                                    <button className="buy-now back-profile width-auto-marg-left">{t("Hire Designer")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-md-6  mb-4'>
                    <div className='card border-10 mb-4'>
                        <div className='padd-20 card-body'>
                            <div className='d-flex align-items-center'>
                                <svg width="48" height="48" className='marg-10' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.76 34H19C16.79 34 15 33 15 30V20.25C15 17 16.79 16.25 19 16.25C19 16.87 19.25 17.43 19.66 17.84C20.07 18.25 20.63 18.5 21.25 18.5H24.75C25.99 18.5 27 17.49 27 16.25C29.21 16.25 31 17 31 20.25V23.26" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M27 16.25C27 17.49 25.99 18.5 24.75 18.5H21.25C20.63 18.5 20.07 18.25 19.66 17.84C19.25 17.43 19 16.87 19 16.25C19 15.01 20.01 14 21.25 14H24.75C25.37 14 25.93 14.25 26.34 14.66C26.75 15.07 27 15.63 27 16.25Z" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 25H23" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 29H20.47" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M29.2 32.65C30.97 32.65 32.4 31.22 32.4 29.45C32.4 27.68 30.97 26.25 29.2 26.25C27.43 26.25 26 27.68 26 29.45C26 31.22 27.43 32.65 29.2 32.65Z" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M33 33.25L32 32.25" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <circle cx="24" cy="24" r="24" fill="#29BD81" fillOpacity="0.1" />
                                </svg>
                                <div className='title-p'>{t("Portfolio")}</div>
                            </div>
                            <div className="gallery">
                                {designer?.portfolioImages && designer?.portfolioImages.map((img: any, index: any) => {
                                    return <div className="gallery-item" key={index}><img src={img} alt="image" /></div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='card border-10 mb-4'>
                        <div className='padd-20 '>
                            <div className='d-flex mb-2 align-items-center'>
                                <svg width="48" height="48" className='marg-10' viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3047 32.2804L18.0482 33.6504C18.2748 33.8804 18.7772 33.9903 19.122 33.9903H21.2595C21.9392 33.9903 22.6682 33.4804 22.8356 32.7904L24.1852 28.6203C24.4708 27.8203 23.9586 27.1303 23.1114 27.1303H20.8557C20.5207 27.1303 20.2351 26.8403 20.2942 26.4403L20.5798 24.6103C20.6882 24.1003 20.3533 23.5203 19.8509 23.3503C19.3978 23.1803 18.8363 23.4103 18.6097 23.7503L16.3047 27.2403" stroke="#29BD81" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M13.4297 32.2799V26.6799C13.4297 25.8799 13.7646 25.5898 14.5527 25.5898H15.1141C15.9022 25.5898 16.2371 25.8799 16.2371 26.6799V32.2799C16.2371 33.0799 15.9022 33.3699 15.1141 33.3699H14.5527C13.7646 33.3699 13.4297 33.0899 13.4297 32.2799Z" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M30.2569 15.7217L28.5134 14.3517C28.2868 14.1217 27.7844 14.0117 27.4397 14.0117H25.3021C24.6224 14.0117 23.8935 14.5217 23.726 15.2117L22.3765 19.3817C22.0908 20.1817 22.603 20.8717 23.4502 20.8717H25.706C26.0409 20.8717 26.3266 21.1618 26.2674 21.5618L25.9818 23.3917C25.8734 23.9017 26.2083 24.4817 26.7107 24.6517C27.1639 24.8217 27.7253 24.5917 27.9519 24.2517L30.2569 20.7617" stroke="#29BD81" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M33.1355 15.7228V21.3228C33.1355 22.1228 32.8006 22.4128 32.0126 22.4128H31.4511C30.663 22.4128 30.3281 22.1228 30.3281 21.3228V15.7228C30.3281 14.9228 30.663 14.6328 31.4511 14.6328H32.0126C32.8006 14.6328 33.1355 14.9128 33.1355 15.7228Z" stroke="#29BD81" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <circle cx="24" cy="24" r="24" fill="#29BD81" fillOpacity="0.1" />
                                </svg>
                                <div className='title-p'>{t("Reviews")}</div>
                            </div>
                            <div className='list-reviews'>
                                <div className='card padd-10 border-10 card-rev mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src="/assets/images/Avatar.svg" width="40px" height="40px" alt="Designer" />
                                        <div className='p-2 bd-highlight'>
                                            <h3 className='h-rev'>John Doe</h3>
                                            <h3 className='h1-rev'>Client</h3>
                                        </div>
                                        <span className="badge-standard rec-span ms-auto">{t("Recomeded")}</span>
                                    </div>
                                    <p className='p-rev'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu</p>
                                    <div className='span-rev'>
                                        <svg width="17" height="17" className='marg-10' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2988 1.43097C7.9064 0.544397 9.2148 0.544397 9.8224 1.43097L11.6248 4.06093C11.8237 4.35116 12.1166 4.56395 12.4541 4.66344L15.5123 5.56495C16.5433 5.86885 16.9476 7.11321 16.2922 7.96504L14.3479 10.492C14.1334 10.7708 14.0215 11.1151 14.0312 11.4668L14.1188 14.654C14.1484 15.7284 13.0899 16.4974 12.0772 16.1373L9.07312 15.0691C8.74161 14.9512 8.37959 14.9512 8.04808 15.0691L5.04403 16.1373C4.03135 16.4974 2.97284 15.7284 3.00239 14.654L3.09004 11.4668C3.09972 11.1151 2.98785 10.7708 2.77329 10.492L0.829022 7.96504C0.173605 7.11321 0.577921 5.86885 1.60886 5.56495L4.66709 4.66344C5.00458 4.56395 5.29746 4.35116 5.49637 4.06093L7.2988 1.43097Z" fill="#FFC72C" />
                                        </svg>
                                        4.0
                                    </div>
                                </div>
                                <div className='card padd-10 border-10 card-rev mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src="/assets/images/Avatar.svg" width="40px" height="40px" alt="Designer" />
                                        <div className='p-2 bd-highlight'>
                                            <h3 className='h-rev'>John Doe</h3>
                                            <h3 className='h1-rev'>Client</h3>
                                        </div>
                                        <span className="badge-standard rec-span ms-auto">{t("Recomeded")}</span>
                                    </div>
                                    <p className='p-rev'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu</p>
                                    <div className='span-rev'>
                                        <svg width="17" height="17" className='marg-10' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2988 1.43097C7.9064 0.544397 9.2148 0.544397 9.8224 1.43097L11.6248 4.06093C11.8237 4.35116 12.1166 4.56395 12.4541 4.66344L15.5123 5.56495C16.5433 5.86885 16.9476 7.11321 16.2922 7.96504L14.3479 10.492C14.1334 10.7708 14.0215 11.1151 14.0312 11.4668L14.1188 14.654C14.1484 15.7284 13.0899 16.4974 12.0772 16.1373L9.07312 15.0691C8.74161 14.9512 8.37959 14.9512 8.04808 15.0691L5.04403 16.1373C4.03135 16.4974 2.97284 15.7284 3.00239 14.654L3.09004 11.4668C3.09972 11.1151 2.98785 10.7708 2.77329 10.492L0.829022 7.96504C0.173605 7.11321 0.577921 5.86885 1.60886 5.56495L4.66709 4.66344C5.00458 4.56395 5.29746 4.35116 5.49637 4.06093L7.2988 1.43097Z" fill="#FFC72C" />
                                        </svg>
                                        4.0
                                    </div>
                                </div>
                                <div className='card padd-10 border-10 card-rev mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src="/assets/images/Avatar.svg" width="40px" height="40px" alt="Designer" />
                                        <div className='p-2 bd-highlight'>
                                            <h3 className='h-rev'>John Doe</h3>
                                            <h3 className='h1-rev'>Client</h3>
                                        </div>
                                        <span className="badge-standard rec-span ms-auto">{t("Recomeded")}</span>
                                    </div>
                                    <p className='p-rev'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu</p>
                                    <div className='span-rev'>
                                        <svg width="17" height="17" className='marg-10' viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2988 1.43097C7.9064 0.544397 9.2148 0.544397 9.8224 1.43097L11.6248 4.06093C11.8237 4.35116 12.1166 4.56395 12.4541 4.66344L15.5123 5.56495C16.5433 5.86885 16.9476 7.11321 16.2922 7.96504L14.3479 10.492C14.1334 10.7708 14.0215 11.1151 14.0312 11.4668L14.1188 14.654C14.1484 15.7284 13.0899 16.4974 12.0772 16.1373L9.07312 15.0691C8.74161 14.9512 8.37959 14.9512 8.04808 15.0691L5.04403 16.1373C4.03135 16.4974 2.97284 15.7284 3.00239 14.654L3.09004 11.4668C3.09972 11.1151 2.98785 10.7708 2.77329 10.492L0.829022 7.96504C0.173605 7.11321 0.577921 5.86885 1.60886 5.56495L4.66709 4.66344C5.00458 4.56395 5.29746 4.35116 5.49637 4.06093L7.2988 1.43097Z" fill="#FFC72C" />
                                        </svg>
                                        4.0
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card border-10 mb-4 height-700'>
                        <div className='padd-20'>
                            <h3 className='h-26 user-25 mb-3'>{t("Personal Info")}</h3>
                            <p className='user-type mb-3'><svg width="24" height="24" className='marg-10' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="#667085" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#667085" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>{designer?.contactEmail} </p>
                            <p className='user-type mb-3'><svg width="24" height="24" className='marg-10' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#667085" strokeWidth="1.5" strokeMiterlimit="10" />
                            </svg>{designer?.phone}</p>
                            <p className='user-type mb-3'><svg width="24" height="24" className='marg-10' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.7128 15.1817L12.6128 13.3317C12.0728 13.0117 11.6328 12.2417 11.6328 11.6117V7.51172" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>{designer?.timezone}</p>
                            <h3 className='h-26 user-25 mt-4 mb-3'>{t("BIO")}</h3>
                            <p className='user-type'>
                                {designer?.bio}
                            </p>
                            <h3 className='h-26 user-25 mt-4 mb-3'>{t("Experience")}</h3>
                            <p className='user-type'>
                                {designer?.experience} {t("Experience")}
                            </p>
                            <h3 className='h-26 user-25 mt-4 mb-3'>{t("Rating & Reviews")}</h3>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='user-type'>
                                    {designer?.reviews}  {t("Reviews")}
                                </p>
                                <svg width="167" height="25" viewBox="0 0 167 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.81469 4.29932C9.46006 3.35764 10.8498 3.35764 11.4952 4.29932L13.4096 7.09278C13.6209 7.40105 13.932 7.62707 14.2905 7.73274L17.5388 8.69029C18.6338 9.01309 19.0633 10.3348 18.3671 11.2396L16.302 13.9236C16.0741 14.2198 15.9553 14.5855 15.9656 14.9591L16.0587 18.3443C16.0901 19.4855 14.9657 20.3024 13.8901 19.9199L10.6993 18.7852C10.3472 18.66 9.96267 18.66 9.61055 18.7852L6.41974 19.9199C5.34412 20.3024 4.2198 19.4855 4.25119 18.3443L4.3443 14.9591C4.35457 14.5855 4.23575 14.2198 4.00785 13.9236L1.94272 11.2396C1.24656 10.3348 1.67601 9.01309 2.77104 8.69029L6.01938 7.73274C6.37785 7.62707 6.68893 7.40105 6.90021 7.09278L8.81469 4.29932Z" fill="#FFC72C" />
                                    <path d="M37.6116 4.29932C38.2569 3.35764 39.6467 3.35764 40.292 4.29932L42.2065 7.09278C42.4178 7.40105 42.7289 7.62707 43.0874 7.73274L46.3357 8.69029C47.4307 9.01309 47.8602 10.3348 47.164 11.2396L45.0989 13.9236C44.871 14.2198 44.7522 14.5855 44.7624 14.9591L44.8555 18.3443C44.8869 19.4855 43.7626 20.3024 42.687 19.9199L39.4962 18.7852C39.1441 18.66 38.7595 18.66 38.4074 18.7852L35.2166 19.9199C34.141 20.3024 33.0167 19.4855 33.0481 18.3443L33.1412 14.9591C33.1514 14.5855 33.0326 14.2198 32.8047 13.9236L30.7396 11.2396C30.0434 10.3348 30.4729 9.01309 31.5679 8.69029L34.8163 7.73274C35.1747 7.62707 35.4858 7.40105 35.6971 7.09278L37.6116 4.29932Z" fill="#FFC72C" />
                                    <path d="M66.4163 4.29932C67.0616 3.35764 68.4514 3.35764 69.0967 4.29932L71.0112 7.09278C71.2225 7.40105 71.5336 7.62707 71.892 7.73274L75.1404 8.69029C76.2354 9.01309 76.6649 10.3348 75.9687 11.2396L73.9036 13.9236C73.6757 14.2198 73.5568 14.5855 73.5671 14.9591L73.6602 18.3443C73.6916 19.4855 72.5673 20.3024 71.4917 19.9199L68.3009 18.7852C67.9488 18.66 67.5642 18.66 67.2121 18.7852L64.0213 19.9199C62.9457 20.3024 61.8214 19.4855 61.8528 18.3443L61.9459 14.9591C61.9561 14.5855 61.8373 14.2198 61.6094 13.9236L59.5443 11.2396C58.8481 10.3348 59.2776 9.01309 60.3726 8.69029L63.6209 7.73274C63.9794 7.62707 64.2905 7.40105 64.5018 7.09278L66.4163 4.29932Z" fill="#FFC72C" />
                                    <path d="M95.2131 4.29932C95.8585 3.35764 97.2482 3.35764 97.8936 4.29932L99.8081 7.09278C100.019 7.40105 100.33 7.62707 100.689 7.73274L103.937 8.69029C105.032 9.01309 105.462 10.3348 104.766 11.2396L102.7 13.9236C102.473 14.2198 102.354 14.5855 102.364 14.9591L102.457 18.3443C102.488 19.4855 101.364 20.3024 100.289 19.9199L97.0977 18.7852C96.7456 18.66 96.3611 18.66 96.009 18.7852L92.8182 19.9199C91.7426 20.3024 90.6182 19.4855 90.6496 18.3443L90.7427 14.9591C90.753 14.5855 90.6342 14.2198 90.4063 13.9236L88.3412 11.2396C87.645 10.3348 88.0744 9.01309 89.1695 8.69029L92.4178 7.73274C92.7763 7.62707 93.0874 7.40105 93.2986 7.09278L95.2131 4.29932Z" fill="#FFC72C" />
                                    <path d="M124.437 4.58636C124.88 3.93896 125.836 3.93896 126.279 4.58636L128.194 7.37982C128.471 7.78443 128.88 8.08107 129.35 8.21977L132.598 9.17732C133.351 9.39924 133.646 10.3079 133.168 10.93L131.103 13.614C130.804 14.0027 130.648 14.4827 130.661 14.973L130.754 18.3583C130.776 19.1428 130.003 19.7044 129.263 19.4415L126.073 18.3068C125.61 18.1425 125.106 18.1425 124.644 18.3068L121.453 19.4415C120.713 19.7044 119.94 19.1428 119.962 18.3583L120.055 14.973C120.068 14.4827 119.913 14.0027 119.613 13.614L117.548 10.93C117.07 10.3079 117.365 9.39924 118.118 9.17732L121.366 8.21977C121.837 8.08107 122.245 7.78443 122.522 7.37982L124.437 4.58636Z" fill="white" stroke="#A4A8AB" strokeWidth="1.01549" />
                                    <path d="M145.385 14.9812V13.4173L150.034 6.76383H152.351V13.2467H153.602V14.9812H152.351V17H150.361V14.9812H145.385ZM150.489 8.89637L147.574 13.2467H150.489V8.89637ZM155.978 17.0995C155.618 17.0995 155.32 16.9905 155.083 16.7725C154.855 16.5451 154.741 16.2655 154.741 15.9337C154.741 15.602 154.855 15.3271 155.083 15.1092C155.32 14.8817 155.618 14.7679 155.978 14.7679C156.329 14.7679 156.618 14.8817 156.846 15.1092C157.073 15.3271 157.187 15.602 157.187 15.9337C157.187 16.2655 157.073 16.5451 156.846 16.7725C156.618 16.9905 156.329 17.0995 155.978 17.0995ZM158.562 11.6829C158.562 10.0432 158.856 8.75894 159.444 7.8301C160.041 6.90126 161.027 6.43685 162.401 6.43685C163.775 6.43685 164.756 6.90126 165.344 7.8301C165.941 8.75894 166.24 10.0432 166.24 11.6829C166.24 13.332 165.941 14.6258 165.344 15.5641C164.756 16.5024 163.775 16.9716 162.401 16.9716C161.027 16.9716 160.041 16.5024 159.444 15.5641C158.856 14.6258 158.562 13.332 158.562 11.6829ZM164.278 11.6829C164.278 10.9815 164.23 10.3939 164.136 9.91998C164.05 9.43661 163.87 9.04328 163.595 8.73998C163.33 8.43669 162.932 8.28504 162.401 8.28504C161.87 8.28504 161.467 8.43669 161.193 8.73998C160.927 9.04328 160.747 9.43661 160.652 9.91998C160.567 10.3939 160.524 10.9815 160.524 11.6829C160.524 12.4032 160.567 13.0098 160.652 13.5026C160.738 13.986 160.918 14.3794 161.193 14.6826C161.467 14.9765 161.87 15.1234 162.401 15.1234C162.932 15.1234 163.335 14.9765 163.609 14.6826C163.884 14.3794 164.064 13.986 164.15 13.5026C164.235 13.0098 164.278 12.4032 164.278 11.6829Z" fill="#FFC72C" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Profile;